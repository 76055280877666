import { useHistory, useLocation } from "react-router";
import TimeCard, { SimplifiedTimeCardWeek } from "../../components/timetracking/timeCards";

import { useContext, useEffect, useRef, useState } from "react";
import { createId, getClientById, getClients, getClientsAndProjectsInOne, getCompanyTimeSettings, getProjectsByCompanyId, getRecordedTimeFromDB, getRecordedTimeFromDBOfCurrentDay, getRecordedTimeOfCurrentDay, getRecordedTimeOfMonth, uploadTimeToDb } from "../../firebase";
import { IonContent, IonModal } from "@ionic/react";
import TimeChooser from "../../components/timetracking/timeChooser";

//import { ReactComponent as SettingsIcon } from '../assets/icons/mdi_settings.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';

import CustomDropdown from "../../components/miniComponents/customDropdown";
import LoadingWhileEmpty from "../../components/miniComponents/loadingWhileEmpty";
import { UserContext } from "../../App";

import { ReactComponent as PlusIcon } from '../../assets/icons/material-symbols_add.svg';

import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import TimePickerCarousel from "../../components/miniComponents/calendarInput";

import { Doughnut } from 'react-chartjs-2';

import { Chart, ArcElement, DoughnutController, Tooltip, Legend } from 'chart.js';
import { getDayName, getMonth } from "./timeføring";
import { useTranslation } from "react-i18next";
import { calculateHoursOfTimes } from "./timeOverview";

// Register the components
Chart.register(ArcElement, DoughnutController, Tooltip, Legend);


const COLORS = [
    '#A155B9',
    '#1DDD8D',
    '#F765A3',
    '#16BFD6',
    '#165BAA',
    '#166a8f',
    '#00a950',
    '#58595b',
    '#8549ba'
];

const filterOptions = createFilterOptions({
    ignoreCase: true,
});


const information = {
    id: createId(),
    //startDate: new Date(),
    //endDate: new Date(),
    title: "",
    pause: false,
    tilleg: {
        id: createId(),
        name: "Normal",
        prosent: 0,
    },

}

export default function FørteTimer(props) {
    const { t } = useTranslation();
    const { userContext } = useContext(UserContext);
    const [months, setMonths] = useState([]);
    const history = useHistory();

    const chartRef = useRef(null);

    const navigateToNewPage = (path, state) => {
        history.push({
            pathname: path,
            state: state
        });
    }

    const setForMonth = async (date) => {
        const setForMonths = async (sortedMonths) => {
            const times = sortedMonths.listOfTimes;

            const weeksInMonth = sortedMonths.weeks
            setMonths(old => {
                if (old.some(month => month.date.getMonth() === date.getMonth() && month.date.getFullYear() === date.getFullYear())) {
                    return old.map(month => {
                        if (month.date.getMonth() === date.getMonth() && month.date.getFullYear() === date.getFullYear()) {
                            return { date: month.date, weeks: weeksInMonth, listOfTimes: times }
                        }
                        return month;
                    })
                }
                return [...old, { date: date, weeks: weeksInMonth, listOfTimes: times }]
            });
        }

        const sortedMonths = await getAllTimesFromMonth(date, setForMonths);
    };

    // Helper function to check if the given date is in the current week
    function isCurrentWeek(date) {
        const today = new Date();
        const todayWeekNumber = getNorwegianWeekNumber(today);
        const dateWeekNumber = getNorwegianWeekNumber(date);
        return todayWeekNumber === dateWeekNumber;
    }


    useEffect(() => {
        setForMonth(new Date());
        const date = new Date();
        date.setMonth(date.getMonth() - 1);
        setForMonth(date);
    }, []);

    const splitIntoTilleggsType = (times) => {
        console.log(times);
        const result = {};
        times.forEach(time => {
            let timeCopy = { ...time };
            if (!time?.tilleg) timeCopy.tilleg = { navn: "Normal" };
            if (!time?.tilleg?.navn && !time?.tilleg?.name) timeCopy.tilleg.navn = "Normal";
            if (!result[timeCopy?.tilleg?.navn || timeCopy?.tilleg?.name]) {
                result[timeCopy?.tilleg?.navn || timeCopy?.tilleg?.name] = [];
            }
            result[timeCopy?.tilleg?.navn || timeCopy?.tilleg?.name].push(timeCopy);
        });
        // console.log(result);
        return result;
    };

    const doughnutData = (times) => {
        const tilleggsType = splitIntoTilleggsType(times);
        let labels = Object.keys(tilleggsType);
        const data = Object.values(tilleggsType).map(tilleg => {
            let totalSeconds = 0;
            tilleg.forEach(time => {
                if (!time.endDate || !time.startDate) return;
                const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
                const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
                totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
            });

            let totalHours = totalSeconds / 3600; // Convert total seconds to hours

            // If total hours are 100 or more, round to nearest whole number
            // Otherwise, round to nearest quarter hour and replace dot with comma for decimals
            return totalHours >= 100 ? Math.round(totalHours) : (Math.floor(totalHours * 2) / 2).toString();
        });


        // Append hour count to each label
        labels = labels.map((label, index) => `${data[index].replace('.', ',')} t - ${label}`);

        return {
            labels: labels,
            datasets: [
                {
                    label: 'Time per Tilleg',
                    data: data,
                    backgroundColor: COLORS,
                    hoverBackgroundColor: ['#FFFFFF'],
                    labelColor: '#FFFFFF',
                    //borderColor: '#faebd700',
                    borderWidth: 0, // Increase the borderWidth to create spacing
                    color: '#FFFFFF',
                    borderRadius: 0,
                    //spacing: 2,

                }
            ]
        };
    };

    console.log(months);

    const options = {
        layout: {
            padding: {
                right: 0, // Or whatever value needed to achieve the desired effect
            },
            autoPadding: false,
        },
        plugins: {
            legend: {
                display: false,
                position: 'right',
                align: 'start',

                labels: {
                    align: 'start',
                    color: '#FFFFFF', // Set text color
                    font: {
                        size: 10, // Set font size
                        family: 'Arial', // Set font family
                    },
                    boxWidth: 20,

                }
            }

        },
        cutout: '70%',
        // Add responsive: true to make the chart adjust to its container
        responsive: true,
        maintainAspectRatio: false // Set to false to allow custom dimensions
    };

    const [doughnutDataState, setDoughnutDataState] = useState(doughnutData(months[0]?.listOfTimes || []));

    useEffect(() => {
        setDoughnutDataState(doughnutData(months[0]?.listOfTimes || []));
        console.log("doughnutDataState")
        console.log(doughnutDataState);
    }, [months]);

    return (
        <div className="column content-ny padding-bottom-for-header">
            {/*<button className="btn btn-primary bottom-right-header-button"
                onClick={() => navigateToNewPage("/timeføring/ny")}
    >Ny Manuell Tid <PlusIcon /></button>*/}

            <LoadingWhileEmpty gottenInformation={months}>
                {months.map((month, index) => {
                    return (
                        <div key={index} className="column">
                            <h2>{getMonth(month.date, t)}</h2>
                            {index === 0 && <div className="time-card row" style={{
                                width: "100%",
                                height: "150px",
                                position: "relative",
                                padding: "20px"
                            }}>
                                <h2 style={{ position: "absolute", top: "50%", left: "75px", transform: "translate(-50%, -50%)" }}>{calculateHoursOfTimes(month.listOfTimes).toString().replace('.', ',')} t</h2>
                                <div style={{ width: "110px", height: "100%" }}>
                                    <Doughnut color={"#FFFFFF"} style={{ width: "310px" }} options={options} data={doughnutDataState} />
                                </div>

                                <div className="column wrap" style={{ paddingBlock: 7.5 }}>
                                    {doughnutDataState.labels.map((label, index) => {
                                        return (
                                            <div className="row small-gap" key={index}>
                                                <div style={{ backgroundColor: COLORS[index], borderRadius: "50%", width: "1em", height: "1em" }}></div>
                                                <p>{label}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>}

                            {month.weeks.map((weekData, weekIndex) => {
                                return (
                                    <SimplifiedTimeCardWeek key={weekIndex} weekData={weekData} onClick={() => {
                                        navigateToNewPage(`/uke/${weekData.weekNumber}`, { week: weekData });
                                    }} />
                                )
                            })}

                        </div>
                    )
                })}

            </LoadingWhileEmpty>
        </div>
    );
}

export function getWeekStartDateForWeekNumber(weekNumber, year) {
    // Start with the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const dayOfWeek = firstDayOfYear.getDay();

    // Adjust to the first Thursday of the year
    const daysToAdd = dayOfWeek <= 4 ? 4 - dayOfWeek : 11 - dayOfWeek;
    firstDayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd);

    // Calculate the start date of the given week number
    const startDate = new Date(firstDayOfYear);
    startDate.setDate(firstDayOfYear.getDate() + (weekNumber - 1) * 7);

    // Adjust to get the Monday of the week
    const day = startDate.getDay();
    startDate.setDate(startDate.getDate() - (day === 0 ? 6 : day - 1));

    return startDate;
}

export async function getAllTimesFromMonth(date, onUpdate) {
    const monthStart = new Date(date.getFullYear(), date.getMonth(), 1);
    const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

    const createWeeksInMonth = (monthStart, monthEnd, times) => {
        const weeksInMonth = generateWeeksInRange(monthStart, monthEnd).map(weekNumber => {
            const weekStartDate = getWeekStartDateForWeekNumber(weekNumber, date.getFullYear());
            const weekEndDate = new Date(weekStartDate);
            weekEndDate.setDate(weekEndDate.getDate() + 6);

            const weekTimes = times.filter(time => {
                const timeDate = createDateOutOfFirebaseTimestamp(time.startDate);
                return getNorwegianWeekNumber(timeDate) === weekNumber;
            });

            return {
                weekNumberYear: date.getFullYear(),
                weekNumber: weekNumber,
                times: weekTimes,
                startDate: weekStartDate,
                endDate: weekEndDate
            };
        }).reverse();
        return weeksInMonth;
    };

    const updateFunction = (times) => {
        onUpdate({ date: date, weeks: createWeeksInMonth(monthStart, monthEnd, times), listOfTimes: times })
    }

    const times = await getRecordedTimeOfMonth(date, onUpdate ? updateFunction : false);
    const weeks = createWeeksInMonth(monthStart, monthEnd, times);

    console.log({ date: date, weeks: weeks, listOfTimes: times });

    return { date: date, weeks: weeks, listOfTimes: times };
};

/*const DATA_COUNT = 5;
const NUMBER_CFG = {count: DATA_COUNT, min: 0, max: 100};
 
const data = {
  labels: ['Red', 'Orange', 'Yellow', 'Green', 'Blue'],
  datasets: [
    {
      label: 'Dataset 1',
      data: [{count: DATA_COUNT, min: 0, max: 100}],
      backgroundColor: Object.values(Utils.CHART_COLORS),
    }
  ]
};*/

export function getAllHoursOfMonth(month) {
    //console.log(month);
    console.log(month);
    console.log(month.listOfTimes);
    const times = month.listOfTimes;
    let totalSeconds = 0;
    times.forEach(time => {
        if (!time.endDate || !time.startDate) return;
        const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
        const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
        totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
    });

    let totalHours = totalSeconds / 3600; // Convert total seconds to hours

    // If total hours are 100 or more, round to nearest whole number
    // Otherwise, round to nearest quarter hour and replace dot with comma for decimals
    return totalHours >= 100 ? Math.round(totalHours).toString() : (Math.floor(totalHours * 2) / 2).toString().replace('.', ',');
}



export function groupTimesByWeeks(times, startDate, endDate) {
    let weekNumbers = generateWeeksInRange(startDate, endDate);
    let weeks = weekNumbers.map(weekNumber => ({ weekNumber, times: [] }));

    times.forEach(time => {
        const date = createDateOutOfFirebaseTimestamp(time.startDate);
        const weekNumber = getNorwegianWeekNumber(date);

        let week = weeks.find(w => w.weekNumber === weekNumber);
        if (week) {
            week.times.push(time);
        }
    });

    return weeks;
}


export function generateWeeksInRange(startDate, endDate) {
    let weeks = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const weekNumber = getNorwegianWeekNumber(currentDate);
        if (!weeks.includes(weekNumber)) {
            weeks.push(weekNumber);
        }
        currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
    }

    return weeks;
}


function getWeeksMajorityMonth(date) {
    const weekStart = getWeekStartDate(date);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6); // Get the end date (Sunday) of the week

    // Check if the month changes within the week
    if (weekStart.getMonth() !== weekEnd.getMonth()) {
        // If the month changes, determine which month has the majority of days
        const midWeek = new Date(weekStart);
        midWeek.setDate(midWeek.getDate() + 3); // Get the middle day of the week

        return midWeek.getMonth(); // Return the month of the middle day
    }

    return weekStart.getMonth(); // If the month doesn't change, return the month of the week start
}




export function getNorwegianWeekNumber(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Set the date to the nearest Thursday: current date + 3 - current day number
    // Make Sunday's day number 7
    newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

    // Get the first day of the year
    const yearStart = new Date(newDate.getFullYear(), 0, 1);

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    // Return the week number
    return weekNo;
}

function getWeekStartDate(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Adjust to get the Monday of the week
    // If it's Sunday (0), set to -6, otherwise subtract the day number - 1
    const dayOfWeek = newDate.getDay();
    const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    newDate.setDate(newDate.getDate() + difference);

    return newDate;
}


export function getDayInMonth(date) {
    let dateCopy = createDateOutOfFirebaseTimestamp(date)
    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }
    if (!date) dateCopy = new Date()

    return dateCopy.getDate()
}

export function createDateOutOfFirebaseTimestamp(timestamp) {
    //console.log(new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000)    )
    //console.log(timestamp)
    if (timestamp?.seconds === undefined || timestamp?.nanoseconds === undefined) return new Date(timestamp)
    return new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000)
}

export function makeDateReadable(date, t) {
    const now = new Date();
    const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Convert `date` to a Date object if it is a Unix timestamp.
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    // If it is under a week old, return day by name.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 7) {
        return getDayName(date, t);
    }

    // If it is within the current month, return day and month.
    if (date >= firstDayOfCurrentMonth) {
        return date.getDate() + ". " + (date.getMonth() + 1);
    }

    // If it is under a year old, return month and day.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 365) {
        return date.getDate() + ". " + (date.getMonth() + 1);
    }

    // If it is over a year old, return year, month, and day.
    return date.getDate() + ". " + (date.getMonth() + 1) + " " + date.getFullYear();
}




export const TimeChoiseModal = ({
    open, start, end, tilleg, tillegOptions,
    onDone, onClose,
    title,
    kunde, kunder,
    projekt, projekter,
}) => {
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)
    const [tillegToUpdate, setTillegg] = useState(tilleg)

    //console.log(tillegOptions)
    //console.log(tilleg)
    //console.log(tillegToUpdate)


    useEffect(() => {
        setStartDate(start)
        setEndDate(end)

    }, [start, end])

    //console.log(startDate, endDate)
    //console.log(start, end)

    return (
        <IonModal isOpen={open} cssClass='my-custom-class' onDidDismiss={onClose}>
            <IonContent>
                <div className="content-ny">
                    <div className="row flexApart">
                        <button className="btn btn-primary" onClick={() => {
                            onClose()
                        }}>Tilbake</button>

                        <button className="btn btn-primary" onClick={() => {
                            onDone(startDate, endDate, tillegToUpdate)
                        }}>Ferdig</button>
                    </div>
                    {tillegOptions &&
                        <div className="column flexApart">
                            <h2>Tillegg</h2>

                            <div className="typeAvTillegg">
                                <CustomDropdown options={tillegOptions} selected={tillegToUpdate} onChange={(e) => setTillegg(e)} />
                            </div>
                        </div>
                    }
                    <h2>Fra</h2>
                    <TimeChooser date={startDate} onChange={(e) => setStartDate(e)} />
                    <h2>Til</h2>
                    <TimeChooser date={endDate} onChange={(e) => setEndDate(e)} />

                </div>
            </IonContent>
        </IonModal>
    )
}
