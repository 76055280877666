import React, { Profiler, useEffect, useState } from "react";
import DefaultWrapper from "../../../components/defaultWrapper";
import CustomDropdown from "../../../components/miniComponents/customDropdown";
import CustomInput from "../../../components/miniComponents/customInput";
import InputNoArea from "../../../components/miniComponents/inputNoArea";


//Icons
import { ReactComponent as ChildIcon } from '../../../assets/icons/mal/child-mal.svg';
import { ReactComponent as ParentIcon } from '../../../assets/icons/mal/parent-mal.svg';
import { ReactComponent as BottomLeftIcon } from '../../../assets/icons/mal/bottom-left-mal.svg';
import { ReactComponent as TopLeftIcon } from '../../../assets/icons/mal/top-left-mal.svg';
import { ReactComponent as ExtenderIcon } from '../../../assets/icons/mal/extender-mal.svg';
import { ReactComponent as BottomLeftParentIcon } from '../../../assets/icons/mal/bottom-left-parent-mal.svg';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/mdi_delete.svg';
import { ReactComponent as UpArrow } from '../../../assets/icons/up-arrow.svg';
import { ReactComponent as DownArrow } from '../../../assets/icons/down-arrow.svg';


import { IonCheckbox } from "@ionic/react";
import { createId, createMal, deleteMal, updateMal } from "../../../firebase";
import { useHistory, useParams } from "react-router";
import CircleIcon from "../../../components/miniComponents/circleIcon";
import FontIconPicker from '@fonticonpicker/react-fonticonpicker';

import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.base-theme.react.css';
import '@fonticonpicker/react-fonticonpicker/dist/fonticonpicker.material-theme.react.css';

import CircleIconButton from "../../../components/miniComponents/circleIconButton";

import * as iconDefs from '../../../components/iconDefs';
import { useTranslation } from "react-i18next";
import CreateTestMal from "./createTestMal";
import { CheckIfOnPC } from "../../../App";




export default function NyMal(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const params = useParams();

    // Get information from history - props.location.state
    // or fetch if not available
    console.log(history.location.state)

    const [mal, setMal] = useState(null);

    useEffect(() => {
        setMal(params.id !== "nyMal" ? history.location.state?.mal : {
            title: "Rapport-mal",
            description: "",
            id: createId(),
            icon: "fas fa-file-alt",
            oppgaver: [
                {
                    title: "Skjema 1",
                    kanHaFlere: true,
                    id: createId(),
                    icon: "fas fa-file-alt",
                    underAlignments: [
                        {
                            name: "Normal",
                            type: { label: "Nummer", value: "number", id: "nummer" },
                            id: createId(),
                        },
                    ]
                },
            ],
        });
    }, [params.id]);



    useEffect(() => {
        console.log(mal)
        //Save if on params.id !== "nyMal"
        if (params.id !== "nyMal") {
            //Save to firebase after 5 seconds of nothing changing
            const timeout = setTimeout(() => {
                console.log("Saving to firebase")
                updateMal(mal.id, mal)
            }, 5000);

            return () => clearTimeout(timeout);
        }
    }, [mal])

    if (!mal) {
        return null; // or a loading spinner, or some placeholder content
    }


    const ExtenderStyle = {
        height: "100px",
        width: "100px",
        //width: "32px",
        aspectRatio: "1/1",
    }

    const checkPositionChild = (index, length, lastInLine) => {
        if (index === length - 1 && lastInLine) {
            return <BottomLeftIcon style={ExtenderStyle}></BottomLeftIcon>
        }
        return <ChildIcon style={ExtenderStyle}></ChildIcon>
    }

    const checkPositionParent = (index, length, children) => {
        if (index === 0) {
            return <div className='row noGap'>
                <TopLeftIcon style={ExtenderStyle}></TopLeftIcon>
                <ExtenderIcon style={ExtenderStyle}></ExtenderIcon>
            </div>
        }
        if (index === length - 1 && !children) {
            return <div className='row noGap'>
                <BottomLeftParentIcon style={ExtenderStyle}></BottomLeftParentIcon>
                <ExtenderIcon style={ExtenderStyle}></ExtenderIcon>
            </div>
        }
        return <div className='row noGap'>
            <ParentIcon style={ExtenderStyle} />
            <ExtenderIcon style={ExtenderStyle}></ExtenderIcon>
        </div>
    }

    const updateName = (array, index, name) => {
        const updatedArray = [...array];
        updatedArray[index].name = name;
        return updatedArray;
    };

    const updateType = (array, index, type) => {
        const updatedArray = [...array];
        updatedArray[index].type = type;
        return updatedArray;
    };

    const updateKanHaFlere = (array, index, kanHaFlere) => {
        const updatedArray = [...array];
        updatedArray[index].kanHaFlere = kanHaFlere;
        return updatedArray;
    };

    const icprops = {
        icons: iconDefs.fontAwesome,
        theme: 'bluegrey',
        renderUsing: 'class',
        value: mal.icon,
        onChange: (icon) => setMal({ ...mal, icon: icon }),
        isMulti: false,
        //renderUsing: "data-icomoon",
        closeOnSelect: true,
    };

    return (
        <DefaultWrapper onBack={() => {
            if (params.id !== "nyMal") updateMal(mal.id, mal);
            if (params.id === "nyMal") createMal(mal);
            console.log("history")
            console.log(window.history)
            window.history.back();
        }
        } skipNormalBehaviour={true}
            DisableHeader={true} lagring={true} extraButton={<button className='button' onClick={() => {
                //updateMal(mal.id, mal)
                history.push(`/templates/${mal.id}/TemplatePreview}`, mal)

            }}>{t("Preview", "Forhåndsvisning")}</button>}>
            <div className='content-ny'>
                <div className="row">
                    <div className='column'>
                        <div className="column">
                            <div className="row flexApart">
                                <h2 className='orange'>Mal</h2>
                                {params.id !== "nyMal" && <button className='button' onClick={() => {
                                    deleteMal(mal.id)
                                    history.push("/reportSettings")
                                }}>{t("Delete template", "Slett Mal")}</button>}
                            </div>

                            <div className="row">
                                <div className='column'>
                                    {/*<h4 className='orange'>{t("Template title", "Mal tittel")}</h4>*/}
                                    <CustomInput value={mal.title} onChange={(e) => setMal({ ...mal, title: e.target.value })} placeholder={t("Template title", "Mal Tittel")} label={t("Template title", "Mal Tittel")} />
                                </div>
                                <div className="column">
                                    {/*<h4 className='orange'>{t("Icon", "Ikon")}</h4>*/}
                                    <button className='round-button'>
                                        <FontIconPicker {...icprops} />
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='button-list column'>
                            {mal.oppgaver.map((oppgave, i) => (
                                <div key={oppgave.id} className='column top-left wrap'>
                                    <div className="row flexApart">
                                        <div className='column noGap'>
                                            <div className='row'>
                                                <CustomInput
                                                    className="big-text"
                                                    label={t("Form title", "Skjema Tittel")}
                                                    value={oppgave.title}
                                                    placeholder={"Skjema " + mal.oppgaver.length}
                                                    onChange={(e) => {
                                                        setMal(prevMal => {
                                                            const updatedOppgaver = [...prevMal.oppgaver];
                                                            updatedOppgaver[i].title = e.target.value;
                                                            return { ...prevMal, oppgaver: updatedOppgaver };
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <div className='column'>
                                            <h4 className='orange'>{t("Icon", "Ikon")}</h4>
                                            <button className='round-button'>
                                                <FontIconPicker {...icprops} value={oppgave.icon} onChange={(icon) => {
                                                    setMal(prevMal => {
                                                        const updatedOppgaver = [...prevMal.oppgaver];
                                                        updatedOppgaver[i].icon = icon;
                                                        console.log(updatedOppgaver)
                                                        return { ...prevMal, oppgaver: updatedOppgaver };
                                                    });
                                                }} />
                                            </button>
                                        </div>
                                    </div>
                                    {oppgave.underAlignments.map((underAlignment, j) => (
                                        <div key={underAlignment.id} className='task-specifics-row row stretch-width center-column wrap'>
                                            <div className='column small-gap'>

                                                <CustomInput
                                                    className="stretch-width "
                                                    label={t("Task", "Oppgave")}
                                                    placeholder={t("Task", "Oppgave") + " " + (j + 1)}
                                                    value={underAlignment.name}
                                                    onChange={(e) => {
                                                        setMal(prevMal => {
                                                            const updatedUnderAlignments = updateName(oppgave.underAlignments, j, e.target.value);
                                                            const updatedOppgaver = [...prevMal.oppgaver];
                                                            updatedOppgaver[i].underAlignments = updatedUnderAlignments;
                                                            return { ...prevMal, oppgaver: updatedOppgaver };
                                                        });
                                                    }} />
                                            </div>

                                            <div className='column small-gap'>
                                                <CustomDropdown
                                                    label={t("Input Type", "Type input")}
                                                    options={[
                                                        { label: t("Number", "Nummer"), value: "number", id: "nummer" },
                                                        { label: t("Text", "Tekst"), value: "text", id: "tekst" },
                                                        { label: t("Checkbox", "Checkbox"), value: "checkbox", id: "checkbox" },
                                                        { label: t("Date", "Dato"), value: "date", id: "dato" },
                                                        { label: t("Big text area", "Stort tekst område"), value: "textarea", id: "textarea" }
                                                    ]}
                                                    selected={underAlignment.type}
                                                    onChange={(value) => {
                                                        console.log(value)
                                                        const updatedUnderAlignments = updateType(oppgave.underAlignments, j, value);
                                                        const updatedOppgaver = [...mal.oppgaver];
                                                        updatedOppgaver[i].underAlignments = updatedUnderAlignments;
                                                        setMal({ ...mal, oppgaver: updatedOppgaver });
                                                    }} />
                                            </div>

                                            <div className='column small-gap center-column flexApart'>
                                                <p className='orange'>{t("Multiple tasks", "Flere oppgaver")}</p>
                                                <IonCheckbox
                                                    checked={underAlignment.kanHaFlere}
                                                    onIonChange={(e) => {
                                                        const updatedUnderAlignments = updateKanHaFlere(oppgave.underAlignments, j, e.target.checked);
                                                        const updatedOppgaver = [...mal.oppgaver];
                                                        updatedOppgaver[i].underAlignments = updatedUnderAlignments;
                                                        setMal({ ...mal, oppgaver: updatedOppgaver });
                                                    }} />
                                            </div>
                                            <button className="button-round" onClick={() => {
                                                const updatedUnderAlignments = [...oppgave.underAlignments];
                                                updatedUnderAlignments.splice(j, 1);
                                                const updatedOppgaver = [...mal.oppgaver];
                                                updatedOppgaver[i].underAlignments = updatedUnderAlignments;
                                                setMal({ ...mal, oppgaver: updatedOppgaver });
                                            }}>
                                                <DeleteIcon />
                                            </button>
                                            {/*j !== oppgave.underAlignments.length - 1 && <div className="long-line"></div>*/}
                                        </div>

                                    ))}

                                    <div className='row flexApart stretch-width'>
                                        <div className="up-down-buttons row">
                                            {i !== 0 &&
                                                <button className="button-round" onClick={() => {
                                                    let updatedOppgaver = [...mal.oppgaver];
                                                    let temp = updatedOppgaver[i];
                                                    updatedOppgaver[i] = updatedOppgaver[i - 1];
                                                    updatedOppgaver[i - 1] = temp;
                                                    setMal({ ...mal, oppgaver: updatedOppgaver });

                                                }}>
                                                    <UpArrow />
                                                </button>}
                                            {i !== mal.oppgaver.length - 1 &&
                                                <button className="button-round" onClick={() => {
                                                    let updatedOppgaver = [...mal.oppgaver];
                                                    let temp = updatedOppgaver[i];
                                                    updatedOppgaver[i] = updatedOppgaver[i + 1];
                                                    updatedOppgaver[i + 1] = temp;
                                                    setMal({ ...mal, oppgaver: updatedOppgaver });

                                                }}>
                                                    <DownArrow />
                                                </button>
                                            }
                                        </div>
                                        <button className="orangeButton"
                                            onClick={() => {
                                                const newUnderAlignment = { name: '', type: { label: "Nummer", value: "number", id: "nummer" }, kanHaFlere: false, id: createId(), icon: mal.icon };
                                                const updatedUnderAlignments = [...oppgave.underAlignments, newUnderAlignment];
                                                const updatedOppgaver = [...mal.oppgaver];
                                                updatedOppgaver[i].underAlignments = updatedUnderAlignments;
                                                setMal({ ...mal, oppgaver: updatedOppgaver });
                                            }}
                                        >{t("Add Task", "Legg til oppgave")} +</button>
                                    </div>
                                    <div className="long-line"></div>
                                </div>
                            ))}

                            <div className='row flexApart'>

                                <button
                                    onClick={() => {
                                        const newOppgave = {
                                            title: '',
                                            kanHaFlere: true,
                                            id: createId(),
                                            icon: "fas fa-file-alt",
                                            underAlignments: [
                                                {
                                                    name: "",
                                                    type: { label: "Nummer", value: "number", id: "nummer" },
                                                    id: createId(),
                                                },
                                            ]
                                        };
                                        const updatedOppgaver = [...mal.oppgaver, newOppgave];
                                        setMal({ ...mal, oppgaver: updatedOppgaver });
                                    }}
                                >{t("Add form", "Legg til skjema")} +</button>
                            </div>
                        </div>
                    </div>
                    {CheckIfOnPC() &&
                        <div className="row" style={{
                            width: "50%",
                        }}>
                            <CreateTestMal mal={mal} />
                        </div>
                    }
                </div>
            </div>
        </DefaultWrapper>
    )
}

/*
{
title: "Rapport-mal",
description: "",
id: "123",
oppgaver: [
    {
        title: "Mål",
        kanHaFlere: true,
        id: "123"
        underAlignments: [
            {
                name: "stk",
                type: "number"
            },
            {
                name: "kr",
                type: "number"
            },
            {
                name: "timer",
                type: "number"
            }
        ]
    }
]
}
*/

/*
<div className='column'>
                <CustomInput value={mal.title} onChange={(e) => {
                    setMal({
                        ...mal,
                        title: e.target.value
                    })
                }}></CustomInput>
                <CustomInput value={mal.description} onChange={(e) => {
                    setMal({
                        ...mal,
                        description: e.target.value
                    })
                }}></CustomInput>

                <div className='column'>
                    {
                        mal.oppgaver.map((oppgave) => {
                            return (
                                <div className='column'>
                                    <CustomInput value={oppgave.title} onChange={(e) => {
                                        oppgave.title = e.target.value;
                                        setMal({
                                            ...mal,
                                            oppgaver: mal.oppgaver
                                        })
                                    }}></CustomInput>
                                    <CustomDropdown options={["Ja", "Nei"]} value={oppgave.kanHaFlere ? "Ja" : "Nei"} onChange={(e) => {
                                        oppgave.kanHaFlere = e.target.value === "Ja";
                                        setMal({
                                            ...mal,
                                            oppgaver: mal.oppgaver
                                        })
                                    }}></CustomDropdown>
                                    <div className='row'>
                                        {
                                            oppgave.underAlignments.map((underAlignment) => {
                                                return (
                                                    <div className='column'>

                                                        <CustomInput value={underAlignment.name} onChange={(e) => {
                                                            underAlignment.name = e.target.value;
                                                            setMal({
                                                                ...mal,
                                                                oppgaver: mal.oppgaver
                                                            })
                                                        }}></CustomInput>
                                                        <CustomDropdown options={["Number", "Text"]} value={underAlignment.type} onChange={(e) => {
                                                            underAlignment.type = e.target.value;
                                                            setMal({
                                                                ...mal,
                                                                oppgaver: mal.oppgaver
                                                            })
                                                        }}></CustomDropdown>

                                                    </div>
                                                )
                                            })
                                        }
                                    </div>


                                </div>
                            )
                        })
                    }
                </div>
            </div>
            */