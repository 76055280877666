

//icons
import { ReactComponent as ChatIcon } from '../assets/icons/material-symbols_chat.svg';
import { ReactComponent as RapportIcon } from '../assets/icons/material-symbols_contact-page.svg';
import { ReactComponent as Warning } from '../assets/icons/material-symbols_warning-rounded.svg';
import { ReactComponent as UtstyrIcon } from '../assets/icons/mdi_wrench.svg';
//import { ReactComponent as KalkulatorIcon } from '../assets/icons/mdi_calculator.svg';
//import { ReactComponent as NotaterIcon } from '../assets/icons/mdi_notebook.svg';
import { ReactComponent as GroupIcon } from '../assets/icons/mdi_account-group.svg';
import { ReactComponent as RoleIcon } from '../assets/icons/eos-icons_role-binding.svg';
import { ReactComponent as WorkIcon } from '../assets/icons/material-symbols_work.svg';
import { ReactComponent as ProgressClockIcon } from '../assets/icons/mdi_progress-clock.svg';

import { ReactComponent as NotaterIcon } from '../assets/icons/mdi_notebook.svg';
import { ReactComponent as AdminIcon } from '../assets/icons/eos-icons_admin.svg';
import { ReactComponent as UserIcon } from '../assets/icons/material-symbols_person.svg';
import { ReactComponent as AlarmIcon } from '../assets/icons/mdi_alarm.svg';
import { ReactComponent as LeftArrowIcon } from '../assets/icons/left-arrow.svg';
import Logo from '../assets/logo.png';

import { AppRegistrationRounded, CalendarToday, EngineeringRounded, Language, PunchClockRounded, SwitchAccountRounded } from '@mui/icons-material';


import React, { createContext } from 'react';

import CircleIcon from "../components/miniComponents/circleIcon";



// Icons
import { ReactComponent as Prosjekter } from '../assets/customIcons/prosjekter.svg';
import { ReactComponent as Kunder } from '../assets/customIcons/Kunder.svg';
import { ReactComponent as Utstyrsoversikt } from '../assets/customIcons/utstyr.svg';


import ButtonBigButton from './miniComponents/buttonBigButton';
import ButtonListButton from './miniComponents/buttonListButton';
import HeaderTabButton from './miniComponents/headerTabButton';
import { useHistory, useLocation } from 'react-router';
import { useContext, useEffect, useState } from 'react';
import { getCompanyDetails, getFirmSettings, logout } from '../firebase';
import { HeaderContext, UserContext } from '../App';
import { Avatar, Divider, ListItemIcon, Menu, MenuItem, IconButton } from '@mui/material';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';
import SickIcon from '@mui/icons-material/Sick';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import ChildFriendlyIcon from '@mui/icons-material/ChildFriendly';
import AttachEmailIcon from '@mui/icons-material/AttachEmail';
import { Logout } from '@mui/icons-material';

import { useTranslation, Trans, getI18n } from 'react-i18next';
import { CompanyContext } from '../contexts/companyContext';


const doNotShow = [
    "/login",
    "/registrer",
    "/company",
    "/createCompany",
    "/resetPassword",
    "/glemt-passord",
]

export default function PcHeader() {
    const { t } = useTranslation();
    const lngs = {
        en: { nativeName: 'English', icon: <span className='fi fi-gb'></span> },
        nb: { nativeName: 'Norsk', icon: <span className='fi fi-no'></span> },
        pl: { nativeName: 'Polski', icon: <span className='fi fi-pl'></span> },
        tr: { nativeName: 'Türkçe', icon: <span className='fi fi-tr'></span> },
    };

    const history = useHistory();
    const location = useLocation();
    const { userContext } = useContext(UserContext);

    const [company, setCompany] = useState({});
    const [open, closed] = useState(true);
    const [moduleSettings, setModuleSettings] = useState({})
    const { companyId } = useContext(CompanyContext);

    const [anchorEl, setAnchorEl] = useState(null);
    const [langAnchorEl, setLangAnchorEl] = useState(null);
    const { showMenu, setShowMenu } = useContext(HeaderContext);
    const openMenu = Boolean(anchorEl);
    const openLangMenu = Boolean(langAnchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (!companyId) return console.log("No company id");
        const onUpdate = (e) => {

            setModuleSettings(e);
        }
        const getSettings = async () => {
            await getFirmSettings("moduleSettings", onUpdate);
        }
        getSettings();
    }, [companyId]);


    async function getCompany() {
        if (!companyId) return console.log("No company id");
        const deails = await getCompanyDetails();
        setCompany(deails);
        //console.log(deails);
    }

    useEffect(() => {
        if (doNotShow.includes(history.location.pathname)) return;
        getCompany();
    }, [userContext, companyId]);

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const logOutEndFunction = () => {
        navigateToNewPage("/login");
    }

    useEffect(() => {
        setShowMenu(true);
    }, [location.pathname]);

    if (doNotShow.includes(location.pathname)) return null;
    return (
        <div className={"pc-header column stretch-height flexApart noGap " +
            (open ? " " : " pc-header-minimized ") +
            (showMenu ? "" : " header-transition-out ")
        }> <div className='pc-header-wrapper stretch-height stretch-width'>
                <div className='pc-header-content column noGap stretch-height stretch-width'>


                    <div className='column noGap'>
                        <div className={'row flexApart stretch-width ' + (open ? "" : "center-row")}>
                            {open && <div />}
                            <button className='minimize' onClick={() => closed(!open)} style={{
                                marginTop: 10
                            }}>
                                <LeftArrowIcon style={{
                                    transform: open ? "rotate(0deg)" : "rotate(180deg)",
                                    transition: 'transform 0.2s ease-in-out',
                                }} />
                            </button>
                        </div>
                        
                        <div className='column center stretch-width padding'>
                            <button className='no-style-button center row' onClick={() => history.push("/")} style={{ width: '100%', height: 'auto', background: 'none', border: 'none', boxShadow: 'none' }}>
                                <img src={company?.logo || Logo
                                } alt="logo" className="logo center clickable" style={{
                                    maxWidth: open ? "160px" : "40px",
                                    maxHeight: "2em",
                                    height: "2em",
                                    objectFit: 'contain'
                                }} />
                                {open && <h2 className='center'>{company?.name || "EffektivJobb"}</h2>}
                            </button>
                        </div>
                        <div className='stretch-width column noGap'>
                            <HeaderTabButton iconOnly={!open} title={t("Administrate", "Administrer")} icon={AdminIcon} permissions={['admin', 'invitereBrukere', 'fjerneBruklere', 'redigereBrukere', 'redigereRoller', 'lageMaler', 'redigereMaler', 'sletteMaler', 'kundeOversikt', 'seAndreSineTimer']}>
                                <HeaderTabButton iconOnly={!open} title={t("Company Settings", "Firma Innstillinger")} permissions={"admin"} navigateTo={"/admin/instillinger"} icon={ChatIcon} />
                                <HeaderTabButton iconOnly={!open} title={t("Company Users", "Ansatte")} permissions={["invitereBrukere", "fjerneBruklere", "redigereBrukere"]} navigateTo={"/brukere"} icon={GroupIcon} />
                                <HeaderTabButton iconOnly={!open} title={t("Roles and Rights", "Roller og Rettigheter")} permissions={"redigereRoller"} navigateTo={"/roller"} icon={RoleIcon} />
                                <HeaderTabButton iconOnly={!open} title={t("Customer overview", "Kundeoversikt")} permissions={"kundeOversikt"} navigateTo={"/clientList"} icon={Kunder} moduleDisabled={moduleSettings?.kundeModul !== true} />
                            </HeaderTabButton>

                            <HeaderTabButton iconOnly={!open} title={t("Work Schedule", "Arbeidsplan")} permissions={"seAndreSineTimer"} navigateTo={"/arbeidsplan"} icon={CalendarToday} moduleDisabled={moduleSettings?.timeføringModul !== true} />
                            {/*
                    <HeaderTabButton iconOnly={!open} title={t("Absence", "Fravær")} icon={CalendarToday} moduleDisabled={moduleSettings?.timeføringModul !== true}>
                        <HeaderTabButton iconOnly={!open} title={t("Holiday", "Ferie")} navigateTo={"/absence/holiday"} icon={AirplanemodeActiveIcon} />
                        <HeaderTabButton iconOnly={!open} title={t("Sick Leave", "Sykemelding")} navigateTo={"/absence/sick-leave"} icon={SickIcon} />
                        <HeaderTabButton iconOnly={!open} title={t("Time Off", "Avspasering")} navigateTo={"/absence/time-off"} icon={DirectionsWalkIcon} />
                        <HeaderTabButton iconOnly={!open} title={t("Parental Leave", "Foreldrepermisjon")} navigateTo={"/absence/parental-leave"} icon={ChildFriendlyIcon} />
                        <HeaderTabButton iconOnly={!open} title={t("self declaration", "Egenmelding")} navigateTo={"/absence/self-declaration"} icon={AttachEmailIcon} />
                    </HeaderTabButton>
                    */}
                            <HeaderTabButton iconOnly={!open} title={t("Timeoverview", "Timeoversikt")} permissions={"seAndreSineTimer"} navigateTo={"/timeAdmin"} icon={PunchClockRounded} moduleDisabled={moduleSettings?.timeføringModul !== true} />
                            <HeaderTabButton iconOnly={!open} title={t("Time Approval", "Timegodkjenning")} permissions={"godkjenneTimer"} navigateTo={"/timegodkjenning"} icon={ProgressClockIcon} moduleDisabled={moduleSettings?.timeføringModul !== true} />


                            <HeaderTabButton iconOnly={!open} title={t("Reports", "Rapporter")} description={t("2 new reports is waiting to be sent", "2 Rapporter venter avsendelse")} navigateTo={"/rapporter"} icon={RapportIcon} moduleDisabled={moduleSettings?.rapportModul !== true} />

                            <HeaderTabButton iconOnly={!open} title={t("Time management", "Timeføring")} navigateTo={"/timeføring"} icon={AlarmIcon} moduleDisabled={moduleSettings?.timeføringModul !== true} />
                            <HeaderTabButton iconOnly={!open} title={t("Tool List", "Utstyrsliste")} description={t("Not any updates to tools from last check", "Ikke noe nytt utstyr fra siste sjekk")} navigateTo={"/utstyrsliste"} icon={Utstyrsoversikt} moduleDisabled={moduleSettings?.utstyrModul !== true} />
                            <HeaderTabButton iconOnly={!open} title={t("Customers", "Kunder")} permissions={"kundeOversikt"} navigateTo={"/clientList"} icon={Kunder} moduleDisabled={moduleSettings?.kundeModul !== true} />
                            <HeaderTabButton iconOnly={!open} title={t("Projects", "Prosjekter")} permissions={"kundeOversikt"} navigateTo={"/prosjekter"} icon={Prosjekter} moduleDisabled={moduleSettings?.prosjektModul !== true} />
                            {/*
                            <HeaderTabButton iconOnly={!open} title={"HMS"} icon={RapportIcon}>
                                <HeaderTabButton iconOnly={!open}
                                    title="Oversikt"
                                    description="HMS oversikt"
                                    icon={EngineeringRounded}
                                    navigateTo={"/hms"}
                                />
                                <HeaderTabButton iconOnly={!open}
                                    title="RUH Skjema"
                                    description="Risiko og ulykkes hendelse"
                                    icon={RapportIcon}
                                    navigateTo={"/hms/ruh/ny"}
                                />
                                <HeaderTabButton iconOnly={!open}
                                    title="Avvik"
                                    description="Avviksrapport"
                                    icon={RapportIcon}
                                    navigateTo={"/hms/avvik/ny"}
                                />
                                <HeaderTabButton iconOnly={!open}
                                    title="SJA Skjema"
                                    description="Sikker jobb analyse"
                                    icon={RapportIcon}
                                    navigateTo={"/hms/sja/ny"}
                                />
                                {/*
                        <HeaderTabButton iconOnly={!open}
                            title="Vernerunde"
                            description="Vernerunde"
                            icon={RapportIcon}
                            navigateTo={"/hms/vernerunde"}
                        />
                        <HeaderTabButton iconOnly={!open}
                            title="Stoffkartotek"
                            description="Stoffkartotek"
                            icon={RapportIcon}
                            navigateTo={"/hms/stoffkartotek"}
                        />
                        
                            </HeaderTabButton>
                            */}
                            {/*<ButtonListButton title={"Kalkulator"} navigateTo={"./kalkulator"} icon={KalkulatorIcon} />*/}
                            <HeaderTabButton iconOnly={!open} title={t("Notes", "Notater")} description={t("Remember to take notes!", "Husk å ta notater!")} navigateTo={"/notater"} icon={NotaterIcon} />
                        </div>
                    </div>

                    {/*<div className='column noGap stretch-width'>
                <HeaderTabButton iconOnly={!open} title={"Min Profil"} icon={UserIcon} navigateTo={"/tab3"} />
                <HeaderTabButton iconOnly={!open} title={"logg ut"} icon={UserIcon} onClick={() => {
                    logout(logOutEndFunction);
                    history.push("/login");
                }} />
            </div>*/}
                    <div className='column noGap'>
                        {/*Object.keys(lngs).map((lng) => (
                    <HeaderTabButton key={lng} iconOnly={!open} title={lngs[lng].nativeName} icon={Language} onClick={() => getI18n().changeLanguage(lng)} />
                ))*/}

                        <HeaderTabButton suffix={<h3 className='beta'>BETA</h3>} iconOnly={!open} title={t("Language", "Språk")} icon={Language} onClick={(e) => {
                            setLangAnchorEl(e.currentTarget);
                        }} />
                        <HeaderTabButton permissions={"admin"} iconOnly={!open} title={t("Modules", "Moduler")} navigateTo={"/moduler"} icon={NotaterIcon} />
                        <div onClick={handleClick} className='column noGap stretch-width' style={{ marginBottom: 20 }}>
                            <HeaderTabButton
                                icon={() => {
                                    return (<div
                                        //size={ "medium"}
                                        sx={{ width: 32, height: 32 }}
                                        aria-controls={open ? 'account-menu' : undefined}
                                        aria-haspopup="true"
                                        aria-expanded={open ? 'true' : undefined}
                                    >
                                        <Avatar src={userContext?.user?.photoUrl} alt="profile picture" style={{
                                            backgroundColor: "var(--contrastColor)",
                                            width: 32,
                                            height: 32,
                                            objectFit: 'contain'
                                        }} />
                                    </div>)
                                }
                                } title={t("My Profile", "Min Profil")} iconOnly={!open} />
                        </div>

                        <Menu anchorEl={langAnchorEl}
                            id="lang-menu"
                            open={openLangMenu}
                            onClose={() => setLangAnchorEl(null)}
                            onClick={() => setLangAnchorEl(null)}
                            //PaperProps={paperProps}

                            transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {Object.keys(lngs).map((lng) => (
                                <MenuItem key={lng} onClick={() => {
                                    getI18n().changeLanguage(lng);
                                    setLangAnchorEl(null);
                                    localStorage.setItem("i18nextLng", lng)
                                }}>
                                    {lngs[lng].nativeName} {lngs[lng].icon}
                                </MenuItem>
                            ))}
                        </Menu>


                        <Menu anchorEl={anchorEl}
                            id="account-menu"
                            open={openMenu}
                            onClose={handleClose}
                            onClick={handleClose}
                            //PaperProps={paperProps}

                            transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <MenuItem onClick={(e) => {
                                handleClose()
                                navigateToNewPage("/profile")
                            }}>
                                <div className='row center small-gap'>
                                    <Avatar src={userContext?.user?.photoUrl} alt="profile picture" style={{
                                        backgroundColor: "var(--contrastColor)",
                                        width: 32,
                                        height: 32,
                                        objectFit: 'contain'
                                    }} /> <h3 className='text-black' style={{ marginLeft: 10, }}>{t("My User", "Min Bruker")}</h3>
                                </div>
                            </MenuItem>
                            <Divider />
                            <MenuItem onClick={(e) => {
                                handleClose()
                                navigateToNewPage("/switch-company")
                            }
                            }>
                                <ListItemIcon>
                                    <SwitchAccountRounded fontSize="small" />
                                </ListItemIcon>
                                {t("Switch Company", "Bytt Firma")}
                            </MenuItem>
                            {/* If I want to move it in here.
                    <MenuItem onClick={(e) => { 
                        handleClose()
                        setLangAnchorEl(e.currentTarget)
                    }}>
                        <ListItemIcon>
                            <Language fontSize="small" />
                        </ListItemIcon>
                        {t("Language", "Språk")}
                    </MenuItem>*/
                            }
                            <MenuItem onClick={async (e) => {
                                handleClose()
                                await logout(logOutEndFunction)
                            }}>
                                <ListItemIcon>
                                    <Logout fontSize="small" />
                                </ListItemIcon>
                                {t("Logg Out", "Logg ut")}
                            </MenuItem>
                        </Menu>
                    </div>

                    {/*
            <div className='column noGap stretch-width stretch-height background' style={{
                position: 'absolute',
                zIndex: -1,
                backdropFilter: 'blur(30px)',
                background: 'rgba(0,0,0,1)',
            }} />
            <div className='column noGap stretch-width stretch-height background' style={{
                background: "linear-gradient(45deg, black, transparent)",
                position: 'absolute',
                zIndex: -2,
                filter: 'blur(2px)',
            }} />
            */}
                </div>
            </div>
        </div>
    )
}

