import { useHistory, useLocation } from "react-router";
import TimeCard, { SimplifiedTimeCardWeek } from "../../../components/timetracking/timeCards";

import { useContext, useEffect, useRef, useState } from "react";
import { createId, getClientById, getClients, getClientsAndProjectsInOne, getCompanyTimeSettings, getProjectsByCompanyId, getRecordedTimeFromDB, getRecordedTimeFromDBOfCurrentDay, getRecordedTimeOfCurrentDay, getRecordedTimeOfMonth, uploadTimeToDb } from "../../../firebase";

import LoadingWhileEmpty from "../../../components/miniComponents/loadingWhileEmpty";
import { UserContext } from "../../../App";

import { ReactComponent as PlusIcon } from '../../../assets/icons/material-symbols_add.svg';

import { getRecordedTimeFromDBbyUserId } from "../../../firebase";
import DefaultWrapper from "../../../components/defaultWrapper";

// Register the components


export default function TimegodkjenningUser(props) {
    const { userContext } = useContext(UserContext);
    const [months, setMonths] = useState([]);
    const [timer, setTimer] = useState([]);
    const history = useHistory();

    const navigateToNewPage = (path, state) => {
        history.push({
            pathname: path,
            state: state
        });
    }

    const splitIntoTimesFromMonth = async (times) => {
        const date = new Date();
        const monthStart = new Date(date.getFullYear() - 2, date.getMonth(), 1);
        const monthEnd = new Date(date.getFullYear(), date.getMonth() + 1, 0);

        console.log(times);

        // Generate weeks for the month
        const weeksInMonth = generateWeeksInRange(monthStart, monthEnd).map(weekNumber => {
            const weekStartDate = getWeekStartDateForWeekNumber(weekNumber, date.getFullYear());
            let weekEndDate = new Date(weekStartDate);
            weekEndDate.setDate(weekEndDate.getDate() + 6);

            // Adjust the end date for the current week to today's date
            if (isCurrentWeek(weekStartDate)) {
                weekEndDate = new Date(); // Set end date to today for the current week
            }

            // Filter times for this week
            const weekTimes = times.filter(time => {
                const timeDate = createDateOutOfFirebaseTimestamp(time.startDate);
                return getNorwegianWeekNumber(timeDate) === weekNumber;
            });

            return {
                weekNumber: weekNumber,
                times: weekTimes,
                startDate: weekStartDate,
                endDate: weekEndDate
            };
        }).reverse();

        console.log(weeksInMonth);
        // Take away weeks that have no times, and sort by lowest week number first
        const filteredWeeks = weeksInMonth.filter(week => week.times.length > 0).sort((a, b) => a.weekNumber - b.weekNumber);

        // Update the state to include the new weeks
        setMonths(old => {
            if (old.some(month => month.date.getMonth() === date.getMonth() && month.date.getFullYear() === date.getFullYear())) {
                return old.map(month => {
                    if (month.date.getMonth() === date.getMonth() && month.date.getFullYear() === date.getFullYear()) {
                        return { date: month.date, weeks: filteredWeeks, listOfTimes: times }
                    }
                    return month;
                })
            }
            return [...old, { date: date, weeks: filteredWeeks, listOfTimes: times }]
        });
    };

    // Helper function to check if the given date is in the current week
    function isCurrentWeek(date) {
        const today = new Date();
        const todayWeekNumber = getNorwegianWeekNumber(today);
        const dateWeekNumber = getNorwegianWeekNumber(date);
        return todayWeekNumber === dateWeekNumber;
    }


    const getTimerTilGodkjenning = async () => {
        const threeMonthsBack = new Date(new Date().setMonth(new Date().getMonth() - 3));

        const onUpdate = (timer) => {
            console.log(timer);
            let timerThatIsSent = [];
            timer.forEach((time) => {
                if (time.status === "sent") {
                    timerThatIsSent.push(time)
                }
            }
            )
            console.log(timerThatIsSent.length);
            if (timerThatIsSent.length === 0) return
            setTimer((prevState) => {
                return [...timerThatIsSent]
            })
        }

        await getRecordedTimeFromDBbyUserId(history.location.state.userId, threeMonthsBack, new Date(), onUpdate)
    }

    useEffect(() => {
        getTimerTilGodkjenning();
    }, [])

    useEffect(() => {
        splitIntoTimesFromMonth(timer);
    }, [timer])

    return (
        <DefaultWrapper>
            <div className="column content-ny padding-bottom-for-header">
                <LoadingWhileEmpty gottenInformation={months}>
                    {months.map((month, index) => (
                        <div key={index} className="column">
                            <h2>{getMonth(month.date)}</h2>
                            {month.weeks.map((weekData, weekIndex) => (
                                <SimplifiedTimeCardWeek key={weekIndex} weekData={weekData} onClick={() => {
                                    navigateToNewPage(`/timegodkjenning/uke/${weekData.weekNumber}`, { week: weekData });
                                }} />
                            ))}
                        </div>
                    ))}
                </LoadingWhileEmpty>
            </div>
        </DefaultWrapper>
    );
}

export function getWeekStartDateForWeekNumber(weekNumber, year) {
    // Start with the first day of the year
    const firstDayOfYear = new Date(year, 0, 1);
    const dayOfWeek = firstDayOfYear.getDay();

    // Adjust to the first Thursday of the year
    const daysToAdd = dayOfWeek <= 4 ? 4 - dayOfWeek : 11 - dayOfWeek;
    firstDayOfYear.setDate(firstDayOfYear.getDate() + daysToAdd);

    // Calculate the start date of the given week number
    const startDate = new Date(firstDayOfYear);
    startDate.setDate(firstDayOfYear.getDate() + (weekNumber - 1) * 7);

    // Adjust to get the Monday of the week
    const day = startDate.getDay();
    startDate.setDate(startDate.getDate() - (day === 0 ? 6 : day - 1));

    return startDate;
}


export function getAllHoursOfMonth(month) {
    //console.log(month);
    const times = month.listOfTimes;
    let totalSeconds = 0;
    times.forEach(time => {
        if (!time.endDate || !time.startDate) return;
        const startDate = createDateOutOfFirebaseTimestamp(time.startDate);
        const endDate = createDateOutOfFirebaseTimestamp(time.endDate);
        totalSeconds += (endDate - startDate) / 1000; // Sum up total seconds
    });

    let totalHours = totalSeconds / 3600; // Convert total seconds to hours

    // If total hours are 100 or more, round to nearest whole number
    // Otherwise, round to nearest quarter hour and replace dot with comma for decimals
    return totalHours >= 100 ? Math.round(totalHours).toString() : (Math.floor(totalHours * 4) / 4).toString().replace('.', ',');
}

export function groupTimesByWeeks(times, startDate, endDate) {
    let weekNumbers = generateWeeksInRange(startDate, endDate);
    let weeks = weekNumbers.map(weekNumber => ({ weekNumber, times: [] }));

    times.forEach(time => {
        const date = createDateOutOfFirebaseTimestamp(time.startDate);
        const weekNumber = getNorwegianWeekNumber(date);

        let week = weeks.find(w => w.weekNumber === weekNumber);
        if (week) {
            week.times.push(time);
        }
    });

    return weeks;
}


export function generateWeeksInRange(startDate, endDate) {
    let weeks = [];
    let currentDate = new Date(startDate);

    while (currentDate <= endDate) {
        const weekNumber = getNorwegianWeekNumber(currentDate);
        if (!weeks.includes(weekNumber)) {
            weeks.push(weekNumber);
        }
        currentDate.setDate(currentDate.getDate() + 7); // Move to the next week
    }

    return weeks;
}


function getWeeksMajorityMonth(date) {
    const weekStart = getWeekStartDate(date);
    const weekEnd = new Date(weekStart);
    weekEnd.setDate(weekEnd.getDate() + 6); // Get the end date (Sunday) of the week

    // Check if the month changes within the week
    if (weekStart.getMonth() !== weekEnd.getMonth()) {
        // If the month changes, determine which month has the majority of days
        const midWeek = new Date(weekStart);
        midWeek.setDate(midWeek.getDate() + 3); // Get the middle day of the week

        return midWeek.getMonth(); // Return the month of the middle day
    }

    return weekStart.getMonth(); // If the month doesn't change, return the month of the week start
}




export function getNorwegianWeekNumber(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Set the date to the nearest Thursday: current date + 3 - current day number
    // Make Sunday's day number 7
    newDate.setDate(newDate.getDate() + 3 - ((newDate.getDay() + 6) % 7));

    // Get the first day of the year
    const yearStart = new Date(newDate.getFullYear(), 0, 1);

    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil((((newDate - yearStart) / 86400000) + 1) / 7);

    // Return the week number
    return weekNo;
}

function getWeekStartDate(date) {
    // Create a new Date object to avoid modifying the original date
    const newDate = new Date(date);

    // Adjust to get the Monday of the week
    // If it's Sunday (0), set to -6, otherwise subtract the day number - 1
    const dayOfWeek = newDate.getDay();
    const difference = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
    newDate.setDate(newDate.getDate() + difference);

    return newDate;
}


export function getDay() {
    const date = new Date()
    const day = date.getDay()
    switch (day) {
        case 0:
            return "Søndag"
        case 1:
            return "Mandag"
        case 2:
            return "Tirsdag"
        case 3:
            return "Onsdag"
        case 4:
            return "Torsdag"
        case 5:
            return "Fredag"
        case 6:
            return "Lørdag"
        default:
            return "Ukjent"
    }
}


export function getMonth(date) {
    //console.log(date)
    // Check if date is a timestamp, if so, convert it to a date
    let dateCopy = new Date(date)


    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }

    if (date === undefined) dateCopy = new Date()
    //console.log(dateCopy, date)

    let month = dateCopy.getMonth()
    if (typeof date === "number") month = date
    //console.log(month)

    switch (month) {
        case 0:
            return "Januar"
        case 1:
            return "Februar"
        case 2:
            return "Mars"
        case 3:
            return "April"
        case 4:
            return "Mai"
        case 5:
            return "Juni"
        case 6:
            return "Juli"
        case 7:
            return "August"
        case 8:
            return "September"
        case 9:
            return "Oktober"
        case 10:
            return "November"
        case 11:
            return "Desember"
        default:
            return "Ukjent"
    }
}

export function getDayInMonth(date) {
    let dateCopy = createDateOutOfFirebaseTimestamp(date)
    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }
    if (!date) dateCopy = new Date()

    return dateCopy.getDate()
}

export function createDateOutOfFirebaseTimestamp(timestamp) {
    //console.log(new Date(timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000)    )
    //console.log(timestamp)
    if (timestamp?.seconds === undefined || timestamp?.nanoseconds === undefined) return new Date(timestamp)
    return new Date(timestamp?.seconds * 1000 + timestamp?.nanoseconds / 1000000)
}

