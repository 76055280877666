import React, { useContext, useEffect, useRef, useState } from "react";
import { BrukerVilkår, parseInput } from "../pages/login/createCompany";
import { createCompany, getCompanyId, getThisCompany, updateCompany } from "../firebase";
import { useHistory, useLocation } from "react-router";
import TilbakeKnapp from "./miniComponents/tilbakeKnapp";
import Canvas from "./canvas";
import { useTranslation } from "react-i18next";
import CustomEditInput from "./miniComponents/customEditInput";
import { UserContext } from "../App";
import ButtonOnlyHitOnce from "./miniComponents/buttonOnlyHitOnce";

export default function SigningWarning() {
    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();
    const { userContext } = useContext(UserContext);
    const [DBCompany, setDBCompany] = useState(null);
    const [company, setCompany] = useState(history?.location?.state);
    const [startedSignering, setStartedSignering] = useState(false);
    const [reRender, setReRender] = useState(false);
    const [shouldBeRendered, setShouldBeRendered] = useState(0);

    const divRef = useRef(null);

    useEffect(() => {
        if (DBCompany?.signering) return;
        if (!company) return;
        if (!DBCompany) return;

        if (divRef.current && shouldBeRendered === 0) setShouldBeRendered(1);
        if (shouldBeRendered === 0 && !divRef.current) return;

        const targetNode = divRef.current;

        const callback = (mutationsList, observer) => {
            for (let mutation of mutationsList) {
                if (mutation.type === 'childList') {
                    if (!document.body.contains(targetNode)) {
                        if (checkForNotShow()) return;
                        console.log('Div has been removed!');
                        window.location.reload();
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);

        const config = { childList: true, subtree: true };

        observer.observe(document.body, config);

        return () => {
            observer.disconnect();
        };
    }, [divRef, DBCompany]);

    useEffect(() => {
        if (reRender) {
            setReRender(false);
        }
    }, [reRender]);

    useEffect(() => {
        const fetchCompany = async () => {
            if (!getCompanyId()) return;
            const onUpdate = (querySnapshot) => {
                setDBCompany(querySnapshot);
            };

            const newCompany = await getThisCompany(onUpdate);
            setCompany(newCompany);
        };
        fetchCompany();
    }, []);

    useEffect(() => {
        if (!company) return;
        if (company?.signering) return;
        if (!company?.selgerKode) return;
        const companyParse = parseInput(company?.selgerKode);
        setCompany(old => ({ ...old, ...companyParse }));
    }, [company?.selgerKode]);

    const checkForNotShow = () => {
        if (!company) return true;
        const path = window.location.pathname;
        const forbiddenPaths = ["/tab3", "/chooseCompany", "/company", "/login", "/register", "/createCompany", "glemt-passord", "/reset-password", "/resetPassword"];
        if (forbiddenPaths.includes(path)) return true;
        if (!userContext?.companyUser) return true;
        if (!userContext?.companyUser?.permissions?.includes("admin") && DBCompany?.signering) return true;
        if (DBCompany?.signering) return true;
        if (reRender) return true;

        return false;
    };

    const onSubmit = async (e) => {
        console.log("Submit");
        e.preventDefault();
        await updateCompany({
            ...company,
            signering: true,
            userAccepted: true,
            userSigned: userContext?.user?.uid
        });
    }

    if (checkForNotShow()) return null;
    if (!DBCompany) return;

    return (
        <form
            className="signingWarning padding column center-column"
            onSubmit={onSubmit}
            ref={divRef}
            style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                zIndex: 100,
                backdropFilter: "blur(10px)",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                overflowX: "hidden",
                overflowY: "scroll",
            }}
        >
            <div className="column" style={{ maxWidth: "1000px" }}>
                <TilbakeKnapp
                    onClick={() => {
                        localStorage.removeItem("companyId");
                    }}
                />
                <h1>{t("Welcome to EffektivJobb", "Velkommen til EffektivJobb")}</h1>
                <h3>{t("You have to sign the user agreement before you can use the company", "Du må signere brukervilkårene før du kan bruke dette firmaet")}</h3>
                <div className="division-line orange"></div>
                <div className="info row column-on-phone center-row">
                    <div className="column small-gap">
                        <h1>{t("Company Information", "Firma Informasjon")}</h1>
                        <CustomEditInput
                            required
                            label={t("Company Name", "Firma Navn")}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    navn: e.target.value
                                });
                            }} value={company?.navn}
                        />
                        <CustomEditInput
                            required
                            label={t("Organization Number", "Organisasjonsnummer")}
                            type="number"
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    orgNr: e.target.value
                                });
                            }} value={company?.orgNr}
                        />
                        <CustomEditInput
                            required
                            label={t("Company Address", "Firma Adresse")}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    addresse: e.target.value
                                });
                            }} value={company?.addresse}
                        />
                        <CustomEditInput
                            required
                            label={t("Postal Code", "Postnummer")}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    postnummer: e.target.value
                                });
                            }} value={company?.postnummer}
                        />
                        <CustomEditInput
                            required
                            label={t("City", "By")}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    by: e.target.value
                                });
                            }} value={company?.by}
                        />
                    </div>
                    <div className="division-line-vertical orange"></div>
                    <div className="column small-gap">
                        <h1>{t("Company Contact Information", "Firma Kontakt Informasjon")}</h1>
                        <h3>{company?.leder} {company?.etternavn}</h3>
                        <CustomEditInput
                            required
                            label={t("Phone Number", "Telefonnummer")}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    nummer: e.target.value
                                });
                            }} value={company?.nummer}
                        />
                        <CustomEditInput
                            required
                            label={t("Email", "Epost")}
                            onChange={(e) => {
                                setCompany({
                                    ...company,
                                    email: e.target.value
                                });
                            }} value={company?.email}
                        />
                    </div>
                    <div className="division-line-vertical orange"></div>
                    <div className="column small-gap">
                        <h1>{t("Payment Information", "Betalings Informasjon")}</h1>
                        <h3>{t("Payment Method:", "Betalingsmetode:")} {company.betalingsMåte} Faktura</h3>
                        {company?.fakturaEpost && (
                            <CustomEditInput
                                required
                                label={t("Invoice Email", "Faktura Epost")}
                                onChange={(e) => {
                                    setCompany({
                                        ...company,
                                        fakturaEpost: e.target.value
                                    });
                                }} value={company?.fakturaEpost}
                            />
                        )}
                    </div>
                </div>
                <div className="division-line orange"></div>
                <h1>{t("User Agreement", "Brukervilkår")}</h1>
                <BrukerVilkår data={company} />
                <div className="flexApart">
                    <div />
                    <ButtonOnlyHitOnce type="submit" className="ny" onClick={() => {
                        console.log(divRef.current.checkValidity())
                        if (!divRef.current.checkValidity()) {
                            console.log("Form is invalid. Please fill out all required fields.");
                            divRef.current.reportValidity(); // Show native browser validation errors
                            return;
                        }
                        
                    }}>
                        {t("Understood", "Jeg forstår brukervilkårene")}
                    </ButtonOnlyHitOnce>
                </div>
            </div>
        </form>
    );
}
