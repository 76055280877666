import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import DefaultWrapper from "../../components/defaultWrapper";
import { SimplifiedTimeCard } from "../../components/timetracking/timeCards";
import { createDateOutOfFirebaseTimestamp } from "./timeføring";
import { getCompanyId, updateTimesInFirebase, uploadTimeToDb } from "../../firebase";
import { useTranslation } from "react-i18next";
import { getAllTimesFromMonth } from "./førte-timer";
import moment from "moment-timezone";



export default function FørteUkeTimer() {
    const { t } = useTranslation();
    const history = useHistory();

    const [timesInWeek, setTimesInWeek] = useState([]);
    const [timesInWeekSortedIntoDays, setTimesInWeekSortedIntoDays] = useState([[]]);

    useEffect(() => {
        const onUpdate = (timesInMonth) => {
            console.log(timesInMonth);
            const weekNumber = history.location.state?.week?.weekNumber || moment().isoWeek();
            const weekNumberYear = history.location.state?.week?.weekNumberYear || new Date().getFullYear();
            console.log(weekNumber, weekNumberYear);
            console.log(timesInMonth.weeks);
            const week = timesInMonth.weeks.find(week => week.weekNumber === weekNumber && week.weekNumberYear === weekNumberYear);
            console.log(week);
            setTimesInWeek(week.times);
        }
        async function beginFetching() {
            console.log(history.location.state?.week);
            const times = await getAllTimesFromMonth(history.location.state?.week?.startDate, onUpdate);
        }
        beginFetching();
    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const sendToGodkjenning = () => {
        const newTimesInWeek = timesInWeek.filter(timeInWeek => {
            return timeInWeek.status !== "sent" && timeInWeek.status !== "godkjent";
        }).map(time => {
            return {
                ...time,
                status: "sent"
            }
        })
        console.log(newTimesInWeek);
        console.log(timesInWeek);


        updateTimesInFirebase(newTimesInWeek, getCompanyId(), history.location.state?.week?.weekNumber);
    }

    useEffect(() => {
        // Sort timeList by day of the week, with norwegian names.

        let sorted = [
            {
                day: t("Sunday", "Søndag"),
                times: []
            },
            {
                day: t("Monday", "Mandag"),
                times: []
            },
            {
                day: t("Tuesday", "Tirsdag"),
                times: []
            },
            {
                day: t("Wednesday", "Onsdag"),
                times: []
            },
            {
                day: t("Thursday", "Torsdag"),
                times: []
            },
            {
                day: t("Friday", "Fredag"),
                times: []
            },
            {
                day: t("Saturday", "Lørdag"),
                times: []
            },
        ]

        timesInWeek.forEach((time) => {
            const date = createDateOutOfFirebaseTimestamp(time.startDate)
            const day = date.getDay()
            sorted[day].times.push(time)
        })
        setTimesInWeekSortedIntoDays(sorted?.reverse())
    }, [timesInWeek])





    return (
        <DefaultWrapper extraButton={
            <button onClick={() => {
                sendToGodkjenning();
                history.goBack();
            }}>{t("Send to confirmation", "Send til godkjenning")}</button>
        }>
            <div className="content-ny">
                <h1>{t("Week", "Uke")} {history.location.state?.week?.weekNumber}</h1>
                {timesInWeekSortedIntoDays.map((day, index) => {
                    if (day?.times?.length === 0) return null;
                    return (
                        <div key={index} className="column">
                            <h2>{day?.day}</h2>
                            {day?.times?.map((time, index) => {
                                return (
                                    <SimplifiedTimeCard key={index} time={time} onClick={() => {
                                        if (time.status === "godkjent") return;
                                        if (time.status === "sent") return;
                                        navigateToNewPage("/timeføring/" + time.id, {
                                            timeToEdit: {
                                                ...time,
                                                open: true,
                                                startDate: createDateOutOfFirebaseTimestamp(time.startDate),
                                                endDate: createDateOutOfFirebaseTimestamp(time.endDate),
                                                //tilleg: time.tilleg,
                                            }
                                        })
                                    }
                                    } />
                                )
                            })}
                        </div>
                    )

                }
                )}
            </div>
        </DefaultWrapper>
    )
}