import { useHistory } from "react-router";
import DefaultWrapper from "../../../components/defaultWrapper";
import TemplatePreview from "./malPreview";
import CreateTestMal from "./createTestMal";



export default function TemplatePreviewPage () {
    const history = useHistory();
    console.log(history.location.state);
    return (
        <DefaultWrapper>
            <CreateTestMal mal={history.location.state} />
        </DefaultWrapper>
    )
}