
import { useContext, useEffect, useRef, useState } from "react";
import DefaultWrapper from "../../../components/defaultWrapper";

import { useHistory, useLocation } from "react-router";
import { addFilesDocuments, createId, deleteFileDocument, getAuthUser, getAuthUserId, getFileDocuments, handleUploadFile } from "../../../firebase";

import { ReactComponent as ImageIcon } from '../../../assets/icons/material-symbols_image-rounded.svg';
import { ReactComponent as PDFIcon } from '../../../assets/icons/material-symbols_picture-as-pdf-rounded.svg';
import { ReactComponent as QuestionMarkIcon } from '../../../assets/icons/material-symbols_question-mark-rounded.svg';
import { ReactComponent as MappeIcon } from '../../../assets/icons/material-symbols_folder-open-rounded.svg';
import { ReactComponent as AddFolderIcon } from '../../../assets/icons/material-symbols_add-folder.svg';
import { ReactComponent as AddFileIcon } from '../../../assets/icons/material-symbols_add_contact.svg';
import { ReactComponent as MoreIcon } from '../../../assets/icons/material-symbols_more-horiz.svg';

import CircleIcon from "../../../components/miniComponents/circleIcon";
import { IonFab, IonFabButton, IonFabList, IonIcon, useIonActionSheet } from "@ionic/react";

import {
    chevronDownCircle,
    chevronForwardCircle,
    chevronUpCircle,
    colorPalette,
    globe,
    add
} from 'ionicons/icons';
import { UserContext } from "../../../App";
import { useTranslation } from "react-i18next";
// Alt av utstyr og dokumenter som PDF-er, bilder, osv. skal kunne lastes opp her.
// Og vises
export default function ProjektDokumenter(props) {
    const { t } = useTranslation();
    const history = useHistory();
    const location = useLocation();
    const [present] = useIonActionSheet();

    const { userContext } = useContext(UserContext);

    const [project, setProject] = useState(location?.state?.projekt)
    const [folder, setFolder] = useState('')
    const [previousFolders, setPreviousFolders] = useState([])

    const [searchTerms, setSearchTerms] = useState({ mappe: folder })

    const [doukumenter, setDokumenter] = useState([])
    const [filteredDokumenter, setFilteredDokumenter] = useState(sortFoldersOnTop(doukumenter))
    const [ionFabOpen, setIonFabOpen] = useState(false)

    const fileInputRef = useRef(null)

    const navigateToNewPage = (path, information) => {
        history.push({
            pathname: path,
            state: information
        });
    }

    const addNewItem = async (e) => {
        if (e.target.files[0] === undefined) return
        const filLink = await handleUploadFile(e.target.files[0], `/${project.id}`, 'dokumenter')

        const user = userContext.companyUser
        const newDokument = {
            filLink: filLink,
            navn: e.target.files[0].name,
            id: createId(),
            type: e.target.files[0].type,
            size: e.target.files[0].size,
            lastetOpp: new Date(),
            lastetOppAv: user,
            prosjekt: project.id,
            modifisert: new Date(),
            modifisertAv: user,
            mappe: folder
        }

        await addFilesDocuments(project, newDokument)
        //localStorage.setItem(`documents${project.id}`, JSON.stringify([...doukumenter, newDokument]))
        setDokumenter(prevDokumenter => sortFoldersOnTop([...prevDokumenter, newDokument]));
    }

    const createFolder = async () => {
        const newFolder = prompt('Hva skal mappen hete?')
        if (newFolder === null) return

        const user = await getAuthUserId()
        const newDokument = {
            filLink: null,
            navn: newFolder,
            id: createId(),
            type: 'mappe',
            size: 0,
            lastetOpp: new Date(),
            lastetOppAv: userContext.companyUser,
            prosjekt: project.id,
            modifisert: new Date(),
            modifisertAv: user,
            mappe: folder !== '' ? folder : ''
        }

        await addFilesDocuments(project, newDokument)

        const sorted = sortFoldersOnTop([...doukumenter, newDokument])
        localStorage.setItem(`documents${project.id}`, JSON.stringify(sorted))
        setDokumenter(sorted)
    }

    useEffect(() => {
        //Save to device storage all documents gathered from firebase
        //When fetching more documents, add them to the device storage
        async function getDocumentsFromFirebase() {
            console.log(`lastSeenFileDocuments${project.id}`)
            //const lastSeen = localStorage.getItem(`lastSeenFileDocuments${project.id}`)

            const newDocs = await getFileDocuments(project)
            console.log(newDocs)
            const combinedNewDocs = sortFoldersOnTop([...doukumenter, ...newDocs])
            setDokumenter(combinedNewDocs)
            //localStorage.setItem(`documents${project.id}`, JSON.stringify(combinedNewDocs))
            //localStorage.setItem(`lastSeenFileDocuments${project.id}`, new Date())
        }
        getDocumentsFromFirebase()
    }, [])

    useEffect(() => {
        setSearchTerms({ mappe: folder })
    }, [folder])


    const checkForWhatImageToShow = (type) => {
        switch (type) {
            case 'application/pdf':
                return PDFIcon
            case 'image/png':
                return ImageIcon
            case 'image/jpeg':
                return ImageIcon
            case 'mappe':
                return MappeIcon
            default:
                return QuestionMarkIcon
        }
    }

    const onDokumentClick = (dokument) => {
        if (dokument.type === 'application/pdf') {
            navigateToNewPage('/pdfViewer', dokument)
        }
        if (dokument.type === 'mappe') {
            setPreviousFolders([...previousFolders, folder])
            setFolder(dokument.id)
        }
    }

    const showMoreOptions = (dokument) => {
        present({
            buttons: [
                {
                    text: t("Open", "Åpne"),
                    handler: () => {
                        onDokumentClick(dokument)
                    }
                },
                {
                    text: t("Download", "Nedlast"),
                    handler: () => {
                        //get file from firebase
                        //download file
                        const link = dokument.filLink
                        // download to device
                        const a = document.createElement("a");
                        a.href = link;
                        a.setAttribute("download", link);
                        a.click();
                    }
                },
                {
                    text: t("Delete", "Slett"),
                    handler: async () => {
                        console.log('Delete clicked');
                        slettDokument(dokument)
                    }
                },
                {
                    text: t("Cancel", "Avbryt"),
                    role: 'cancel',
                    handler: () => {
                        console.log('Cancel clicked');
                    }
                }
            ]
        });
    }

    const slettDokument = async (dokument) => {
        async function deleteFolder(folder) {
            //Get all documents in folder and delete them.
            const documentsInFolder = doukumenter.filter((dok) => dok.mappe === folder.id)
            
            for (let i = 0; i < documentsInFolder.length; i++) {
                const doc = documentsInFolder[i];
                if (doc.type === 'mappe') {
                    deleteFolder(doc)
                }
                await deleteFileDocument(project, doc)
            }
        }

        if (dokument.type === 'mappe' && !window.confirm(`Er du sikker på at du vil slette denne mappen? Dette vil slette alle filene i den også.`)) return
        if (dokument.type !== 'mappe' && !window.confirm(`Er du sikker på at du vil slette ${dokument.navn}?`)) return
        if (dokument.type === 'mappe') {
            //Get all documents in folder and delete them.
            const documentsInFolder = doukumenter.filter((dok) => dok.mappe === dokument.id)
            for (let i = 0; i < documentsInFolder.length; i++) {
                const doc = documentsInFolder[i];
                if (doc.type === 'mappe') {
                    deleteFolder(doc)
                }
                await deleteFileDocument(project, doc)
            }
        }
        await deleteFileDocument(project, dokument)
        const newDokumenter = doukumenter.filter((dok) => dok.id !== dokument.id)
        //localStorage.setItem(`documents${project.id}`, JSON.stringify(newDokumenter))
        setDokumenter(newDokumenter)
    }


    return (
        <DefaultWrapper søkefelt={true} searchTerms={searchTerms} updateFilteredItems={(e) => setFilteredDokumenter(sortFoldersOnTop(e))} itemsToFilter={doukumenter} doNotShowScoreUnderZero={true}>
            <div className='content-ny'>
                <input ref={fileInputRef} type="file" name="file" id="file" className="inputfile" onChange={addNewItem} />
                <div className='column'>
                    <div className='column flexApart'>
                        <h1>{t("Documents", "Dokumenter")}</h1>
                        {previousFolders.length !== 0 ?
                            <div className='row flexApart'>
                                <button onClick={() => {
                                    if (previousFolders.length === 0) return
                                    setFolder(previousFolders[previousFolders.length - 1])
                                    setPreviousFolders(previousFolders.slice(0, previousFolders.length - 1))
                                }}>{t("Back", "Tilbake")}</button>
                                <button onClick={() => {
                                    console.log(folder)
                                    slettDokument({ id: folder, type: 'mappe' })
                                    setFolder(previousFolders[previousFolders.length - 1])
                                    setPreviousFolders(previousFolders.slice(0, previousFolders.length - 1))
                                }}>{t("Delete this folder", "Slett Denne Mappen")}</button>
                            </div>

                            : <div />
                        }
                    </div>
                </div>

                <div className='gap column small-gap'>
                    {filteredDokumenter.map((dokument) => {
                        if (dokument.mappe !== folder) return
                        return (
                            <div className="row center" key={dokument.id}>
                                <button className='dokument row stretch-width' style={{ overflow: 'hidden' }} key={dokument.id} onClick={(e) => onDokumentClick(dokument)}>
                                    {<CircleIcon icon={checkForWhatImageToShow(dokument.type)} />
                                    }
                                    <div className='column stretch-width' >
                                        <h2 className={dokument?.type === 'mappe' ? 'orange' : null} style={{
                                            /*white-space: nowrap;
                                            overflow: hidden;
                                            max-width: 100%;
                                            text-overflow: ellipsis;*/
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            maxWidth: '100%',
                                            textOverflow: 'ellipsis',
                                            margin: 0
                                        }}>{dokument.navn}</h2>
                                        <p>{dokument.lastetOppAv.navn}</p>
                                    </div>
                                </button>
                                {dokument.type !== 'mappe' &&
                                    <button className="button-no-style" style={{ paddingBlock: 20 }} onClick={(e) => showMoreOptions(dokument)}>
                                        <MoreIcon style={{ height: 42, width: 42 }} />
                                    </button>
                                }
                            </div>
                        )
                    })}
                </div>

            </div>
            <IonFab slot="fixed" vertical="bottom" horizontal="end" activated={ionFabOpen} open={ionFabOpen} onDidDismiss={() => setIonFabOpen(false)}>
                <IonFabButton onClick={() => setIonFabOpen(true)}>
                    <IonIcon icon={add}></IonIcon>
                </IonFabButton>
                <IonFabList side="top" className="custom-ionfablist">
                    <button className="ionFabButton input-background" onClick={() => { createFolder(); setIonFabOpen(false) }}>
                        <p>{t("Add folder", "Legg til mappe")}</p>
                        <AddFolderIcon />
                    </button>

                    <button className="ionFabButton input-background" onClick={() => { fileInputRef.current.click(); setIonFabOpen(false) }}>
                        <p>{t("Upload a document", "Last opp dokument")}</p>
                        <AddFileIcon />
                    </button>
                </IonFabList>
            </IonFab>


        </DefaultWrapper >
    );
}


function sortFoldersOnTop(array) {
    const sorted = array.sort((a, b) => {
        if (a.type === 'mappe' && b.type !== 'mappe') return -1
        if (a.type !== 'mappe' && b.type === 'mappe') return 1
        return 0
    })
    console.log(sorted)
    return sorted
}