import { useHistory } from "react-router";
import DefaultWrapper from "../../components/defaultWrapper";
import { MyMap } from "../../components/miniComponents/searchAddresses";
import { useContext, useState } from "react";
import { Check, DirectionsWalkRounded } from "@mui/icons-material";
import { createDateOutOfFirebaseTimestamp, makeDateReadable } from "./timeføring";
import { formatedDate } from "../../components/timetracking/timelisteCard";
import { checkForTimeOverlap, gjennomførtTime, gjennomførtTimeIgnoreOverlap } from "../../firebase";
import { AlertContext, UserContext } from "../../App";
import { useTranslation } from "react-i18next";




export default function ArbeidsplanTime() {
    const { t } = useTranslation();
    const history = useHistory();

    const [time, setTime] = useState(history?.location?.state)

    const { userContext } = useContext(UserContext)
    const { setAlertContext, setOnConfirm } = useContext(AlertContext)

    const start = createDateOutOfFirebaseTimestamp(time?.startDate)
    const end = createDateOutOfFirebaseTimestamp(time?.endDate)
    const date = createDateOutOfFirebaseTimestamp(time?.dato)

    const onConfirm = async () => {
        console.log("Ran onConfirm")
        await gjennomførtTimeIgnoreOverlap(time, userContext.user)
        // go back with history state as time id
        console.log(time)
        history.push({
            pathname: "/timeføring",
            state: time
        })
        //history.goBack(time.id)
    }

    return (
        <DefaultWrapper extraButton={<div>
            <button onClick={async (e) => {
                history.push({
                    pathname: "/timeføring/" + time.id,
                    state: {
                        timeToEdit: time,
                        noDeleteButton: true
                    }
                })
            }}>{t("Complete", "Gjennomfør")} <Check />
            </button>
        </div>}>
            <div className="content-ny column">
                {time?.prosjekt?.lat &&
                    <MyMap lat={time?.prosjekt?.lat} lng={time?.prosjekt?.lng} />
                }
                <button className="stretch-width orangeButton" onClick={() => {
                    // Ask for time off
                }}>{t("Request time off", "Be om avspasering")} <DirectionsWalkRounded /></button>
                <div className="card column">
                    <div className="flexApart column">
                        <h1>{time?.prosjekt?.navn || "Ingen Prosjekt Valgt"}</h1>
                    </div>
                    <div className="column flexApart stretch-width wrap row-size-dependant">
                        <h2>{
                            // If date is within the next 7 days, makeDateReadable 
                            start.getTime() - new Date().getTime() < 6 * 24 * 60 * 60 * 1000 ?
                                makeDateReadable(date, t) :
                                formatedDate(date)
                        }</h2>
                        <h2>{start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} - {end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}</h2>
                    </div>
                </div>
                <div className="row stretch-width">
                    {time?.utstyr && <Utstyrsliste utstyr={time?.utstyr} />}
                </div>
            </div>
        </DefaultWrapper>
    )
}


export function Utstyrsliste({ utstyr }) {
    const { t } = useTranslation();
    return (
        <div className="column stretch-width card">
            <h2>{t("Equipment", "Utstyr")}</h2>
            {utstyr?.map((utstyr) => (
                <div className="column small-gap stretch-width center">
                    <div className="row stretch-width">
                        <div className="row relative">
                            <img src={utstyr?.bilde} alt={utstyr?.navn} key={utstyr?.navn} style={{ width: 32, height: 32, borderRadius: 50 }}></img>
                            <div className="circle">
                                <p className="absolute">{utstyr?.quantity}</p>
                            </div>
                        </div>

                        <h1>{utstyr?.navn}</h1>

                    </div>
                    <div className="division-line" />
                </div>
            ))}
        </div>
    )
}
