import DefaultWrapper from "../../components/defaultWrapper";
import { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import CustomInput from "../../components/miniComponents/customInput";
import CustomTextareaInput from "../../components/miniComponents/customTextareaInput";
import { UserContext } from "../../App";





export default function Holiday() {
    const { t } = useTranslation();
    const { user } = useContext(UserContext)
    const [settings, setSettings] = useState({
        fromDate: new Date(),
        toDate: new Date(),
        reason: "",
        status: "pending",
        comments: [],
        user: user
    });

    const [requests, setRequests] = useState([
        {
            fromDate: new Date(),
            toDate: new Date(),
            reason: "",
            status: "declined",
            comments: []
        },
        {
            fromDate: new Date(),
            toDate: new Date(),
            reason: "",
            status: "approved",
            comments: []
        },
        {
            fromDate: new Date(),
            toDate: new Date(),
            reason: "",
            status: "pending",
            comments: []
        }


    ]);

    const send = () => {

    }


    return (
        <DefaultWrapper>
            <div className="content-ny">
                <h1>{t("Holiday", "Ferie")}</h1>
                <div className="column">
                    <div className="row column-on-phone">
                        <CustomInput label={t("From Date", "Fra Dato")} type="date" value={settings.fromDate} onChange={(e) => setSettings({ ...settings, fromDate: e.target.value })} noIcon={true} />
                        <CustomInput label={t("To Date", "Til Dato")} type="date" value={settings.toDate} onChange={(e) => setSettings({ ...settings, toDate: e.target.value })} noIcon={true} />
                    </div>
                    <CustomTextareaInput label={t("Reason", "Årsak")} value={settings.reason} onChange={(e) => setSettings({ ...settings, reason: e.target.value })} />
                    <div className="row flexApart">
                        <div />
                        <button className="orangeButton" onClick={() => send()}>{t("Send", "Send")}</button>
                    </div>
                </div>

                <div className="column">
                    <h2>{t("Already made holiday requests", "Allerede sendte ferieforespørsler")}</h2>
                    <div className="row flexApart">
                        <p>{t("Period", "Periode")}</p>
                        
                        <p>{t("Status", "Status")}</p>
                    </div>
                    <div className="column small-gap">
                        {requests.map((request) => {
                            return (
                                <div className="row flexApart simple-card center-column">
                                    <p>{request.fromDate.toLocaleDateString()} - {request.toDate.toLocaleDateString()}</p>
                                    <p>{request?.sentDate}</p>
                                    <p className={"status " + (request.status === "approved" ? "good" : request.status === "declined" ? "recording" : "orangeButton")}
                                    >{t(request.status, request.status)}</p>
                                </div>
                            )
                        })}
                    </div>
                </div>

            </div>
        </DefaultWrapper>
    )
}