import React, { useState, useEffect } from 'react';

const Timer = ({ startDate, stopDate, onChange, countUp, showSeconds }) => {
    const calculateTimePassed = () => {
        const start = startDate ? new Date(startDate) : new Date();
        const end = stopDate ? new Date(stopDate) : new Date();
        if (start > end) {
            return 0;
        }
        const timePassed = end - start;
        return Math.floor(timePassed / 1000); // timePassed is in milliseconds, divide by 1000 for seconds
    };

    const formatTime = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        seconds = seconds % 60;

        // Return hours, minutes and seconds
        return {
            hours,
            minutes,
            seconds
        };
    };

    const [totalSeconds, setTotalSeconds] = useState(calculateTimePassed());

    useEffect(() => {
        setTotalSeconds(calculateTimePassed());
    }, [startDate, stopDate]);

    useEffect(() => {
        if (countUp) {
            const intervalId = setInterval(() => {
                setTotalSeconds(totalSeconds => totalSeconds + 1);
            }, 1000);

            // Clean up function
            return () => clearInterval(intervalId);
        }
    }, [countUp]);

    const time = formatTime(totalSeconds);

    useEffect(() => {
        if (typeof onChange === 'function') {
            onChange(time);
        }
    }, [time, onChange]);

    const roundToNearest15Minutes = (minutes) => {
        if (minutes < 15) {
            return 0;
        } else if (minutes < 30) {
            return 15;
        } else if (minutes < 45) {
            return 30;
        } else {
            return 45;
        }
    }

    return (
        <div className='row noGap'>
            <p>{time.hours > 9 ? time.hours : '0' + time.hours}:</p>
            <p>{time.minutes > 9 ? roundToNearest15Minutes(time.minutes) : '0' + roundToNearest15Minutes(time.minutes)}</p>
            {showSeconds && <p>:{time.seconds > 9 ? time.seconds : '0' + time.seconds}</p>}
        </div>
    );
};

export default Timer;
