import { useContext, useEffect, useState } from "react"
import { getAuthUser, getTimeListForUser, gjennomførtTimeIgnoreOverlap } from "../../firebase"
import TimelisteCard from "../../components/timetracking/timelisteCard"
import { createDateOutOfFirebaseTimestamp } from "./timeføring"
import { navigate } from "ionicons/icons"
import { useHistory } from "react-router"
import { UserContext } from "../../App"



export default function Timeliste() {
    const history = useHistory()
    const [timeList, setTimeList] = useState([])
    const [sortedTimeList, setSortedTimeList] = useState([[]])
    const { userContext } = useContext(UserContext)

    useEffect(() => {
        //Sort timeList by date
        //Create a new array for each date
        //Add each time to the correct array
        //Set sortedTimeList to the new array
        let sorted = [[]]
        let lastDate = ""
        timeList.forEach((time) => {
            const date = createDateOutOfFirebaseTimestamp(time.dato)
            if (date !== lastDate) {
                sorted.push([])
                lastDate = date
            }
            sorted[sorted.length - 1].push(time)
        })
        setSortedTimeList(sorted)
    }, [timeList])

    useEffect(() => {
        const onUpdate = (querySnapshot) => {
            console.log(querySnapshot)
            setTimeList(querySnapshot.reverse())
        }

        async function startGetTimeListForUser() {

            const userUID = await getAuthUser()
            const uid = userUID.uid
            console.log(userUID)
            getTimeListForUser(onUpdate, uid)
        }
        startGetTimeListForUser()
    }, [])

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    const confirmAllTimes = async () => {
        console.log("Ran onConfirm")
        timeList.forEach(async (time) => {
            await gjennomførtTimeIgnoreOverlap(time, userContext.user)
        })
        // go back with history state as time id
        //history.goBack(time.id)
    }

    //TimelisteCard(date, timeStart, timeEnd)
    return (
        <div className="column content-ny">
            {/*<button onClick={confirmAllTimes}>Gjennomfør alle</button>*/}
            {timeList.map((time) => (
                <TimelisteCard
                    time={time}
                    key={time.id}
                    addresse={`${time?.place?.addresse}, ${time?.place?.postnummer}`}
                    date={createDateOutOfFirebaseTimestamp(time.dato)}
                    timeStart={createDateOutOfFirebaseTimestamp(time.startDate)}
                    timeEnd={createDateOutOfFirebaseTimestamp(time.endDate)}
                    kommentar={time.kommentar}
                    prosjekt={time.prosjekt}
                    onClick={() => {
                        navigateToNewPage(`/arbeidsplan/${time.id}`, time)
                     }}
                />
            ))}
        </div>
    )
}