import { useHistory, useLocation } from "react-router";
import TimeCard, { calculateAllTimePassed, SimplifiedTimeCard } from "../../components/timetracking/timeCards";
import CircleIcon from "../../components/miniComponents/circleIcon";
import Timer from "../../components/timetracking/timer";


import { ReactComponent as AlarmIcon } from '../../assets/icons/mdi_alarm.svg';
import CustomInput from "../../components/miniComponents/customInput";
import { useContext, useEffect, useState } from "react";
import { checkForTimeOverlap, createId, getClientById, getClients, getClientsAndProjectsInOne, getCompanyTimeSettings, getProjectsByCompanyId, getRecordedTimeFromDB, getRecordedTimeFromDBOfCurrentDay, getRecordedTimeOfCurrentDay, uploadTimeToDb } from "../../firebase";
import { IonContent, IonModal } from "@ionic/react";
import TimeChooser from "../../components/timetracking/timeChooser";

//import { ReactComponent as SettingsIcon } from '../assets/icons/mdi_settings.svg';
import { ReactComponent as PauseIcon } from '../../assets/icons/mdi_hamburger.svg';
import { ReactComponent as PlayIcon } from '../../assets/icons/ph_play-fill.svg';
import CustomDropdown from "../../components/miniComponents/customDropdown";
import LoadingWhileEmpty from "../../components/miniComponents/loadingWhileEmpty";
import { AlertContext, UserContext } from "../../App";

import { ReactComponent as PlusIcon } from '../../assets/icons/material-symbols_add.svg';


import TextField from '@mui/material/TextField';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import { styled, lighten, darken } from '@mui/system';
import { navigate } from "ionicons/icons";
import { DatePicker } from "@mui/x-date-pickers";
import TimePickerCarousel from "../../components/miniComponents/calendarInput";
import { SquareRounded } from "@mui/icons-material";
//const moment = require('moment-timezone');
import moment from 'moment-timezone';
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import { Popper } from "@mui/material";
import ButtonOnlyHitOnce from "../../components/miniComponents/buttonOnlyHitOnce";



const filterOptions = createFilterOptions({
    ignoreCase: true,
});


const information = {
    id: createId(),
    //startDate: new Date(),
    //endDate: new Date(),
    title: "",
    pause: false,
    tilleg: {
        id: createId(),
        name: "Normal",
        prosent: 0,
    },

}

export default function Timeføring(props) {
    const { userContext } = useContext(UserContext)
    const { t } = useTranslation()
    const { alertContext, setAlertContext } = useContext(AlertContext)
    const [time, setTime] = useState({
        id: createId(),
        startDate: undefined,
        endDate: null,
        title: "",
        pause: false,
        tilleg: {
            id: "test",
            name: "Normal",
            prosent: 0,
        },
        stoppet: false,
        kunde: null,
        prosjekt: null,
        bruker: userContext.companyUser,
        kommentar: "",
    })
    const history = useHistory();

    const [previousRecordings, setPreviousRecordings] = useState([])
    const [arrayOfPreviousRecordings, setArrayOfPreviousRecordings] = useState([])

    const [currentlyViewing, setCurrentlyViewing] = useState(new Date())
    const [currentlyViewingPrevTimeStamps, setCurrentlyViewingPrevTimeStamps] = useState([])


    const [kunder, setKunder] = useState({})
    const [prosjekter, setProsjekter] = useState({})
    const [fetchedTimes, setFetchedTimes] = useState(false)

    const [kunderWithProjects, setKunderWithProjects] = useState([])
    console.log(kunderWithProjects)
    const flattenedOptions = kunderWithProjects.reduce((acc, client) => {
        client.projects.forEach(project => {
            acc.push({ ...project, clientName: client.navn, clientId: client.id });
        });
        return acc;
    }, []);

    useEffect(() => {
        const getClientsWithP = async () => {
            const kunderWithProjects = await getClientsAndProjectsInOne()
            setKunderWithProjects(kunderWithProjects)
        }
        getClientsWithP()
    }, [history.location.pathname])

    useEffect(() => {
        setTime((old) => { return { ...old, bruker: userContext.companyUser } })
    }, [userContext.companyUser])

    useEffect(() => {
        const onUpdate = (data) => {
            //console.log(data);  // Add this
            const documents = data.docs.map((doc) => {
                return { ...doc.data() }
            })
            setKunder(documents)
            if (time.kunde || time.kunde !== null) return

            setTime((old) => {
                console.log(old)
                console.log(documents[0])
                return { ...old, kunde: documents[0] }
            })
        }

        console.log("Getting clients")


        getClients(onUpdate)
    }, [])

    const [countUp, setCountUp] = useState(false)
    //const information = location.state

    const onEdit = (e) => {
        setTime({ ...time, title: e.target.value })
    }

    useEffect(() => {
        const onUpdate = (data) => {
            //console.log(data);  // Add this

            setPreviousRecordings(data)
        }

        getRecordedTimeFromDB(onUpdate)
        setFetchedTimes(true)
    }, [])

    useEffect(() => {
        const onUpdate = (data) => {
            //console.log(data);  // Add this
            console.log("Getting time of current day")
            setCurrentlyViewingPrevTimeStamps(data)
        }

        const getRecordedTimeFromDBOfCurrentDayFromDB = async () => {
            const docs = await getRecordedTimeOfCurrentDay(currentlyViewing, onUpdate)
            console.log(docs)
            setCurrentlyViewingPrevTimeStamps(docs)
        }
        getRecordedTimeFromDBOfCurrentDayFromDB()

    }, [currentlyViewing])


    useEffect(() => {
        // If we find a recording that has no end date, we set the timer to count up
        let found = previousRecordings.find((recording) => {
            return !recording.endDate
        })

        if (found) {
            found.startDate = createDateOutOfFirebaseTimestamp(found.startDate)
            //console.log(found)
            //console.log(time)
            setTime({ ...time, ...found })
            setCountUp(true)
        } /*else {
            // This has to be here due to the updates server-side enabled the found, but it's just for a brief moment.
            setTime({ ...time, startDate: undefined, endDate: null })
            setCountUp(false)
        }*/
    }, [previousRecordings])

    useEffect(() => {
        const groupedByDay = previousRecordings.reduce((acc, current) => {
            //console.log(current.startDate); // add this line
            if (!current.endDate) return acc;
            if (current.startDate) {
                const date = createDateOutOfFirebaseTimestamp(current.startDate) // convert Firestore timestamp to JS Date

                if (!isNaN(date)) {
                    const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

                    if (!acc.has(day)) {
                        acc.set(day, []);
                    }

                    acc.get(day).push(current);
                }
            }


            return acc;
        }, new Map());


        for (let [_, recordings] of groupedByDay) {
            recordings.sort((a, b) => {
                if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
                    return b.endDate.toDate() - a.endDate.toDate();
                } else {
                    return 0;
                }
            });
        }

        const arrayOfPreviousRecordings = Array.from(groupedByDay.entries()).sort().reverse();


        //console.log(arrayOfPreviousRecordings);  // And this

        setArrayOfPreviousRecordings(arrayOfPreviousRecordings);
    }, [previousRecordings]);

    useEffect(() => {
        if (previousRecordings.length > 0) {
            // Find the most recent recording
            const mostRecentRecording = previousRecordings.reduce((latest, recording) => {
                const latestDate = latest.startDate ? new Date(latest.startDate.seconds * 1000) : new Date(0);
                const recordingDate = recording.startDate ? new Date(recording.startDate.seconds * 1000) : new Date(0);
                return recordingDate > latestDate ? recording : latest;
            }, {});

            // If most recent recording has a project, set it as default
            if (mostRecentRecording.prosjekt && !mostRecentRecording.prosjekt) {
                setTime((old) => ({ ...old, prosjekt: mostRecentRecording.prosjekt }));
            }
        }
    }, [previousRecordings]);

    useEffect(() => {
        const updateTime = async () => {
            const timeCopy = { ...time }
            await uploadTimeToDb(timeCopy)
        }

        if (time.startDate && !time.endDate) updateTime()
    }, [time])



    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }


    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = getDayName(currentlyViewing, t) + " " + currentlyViewing.getDate() + ". " + getMonth(currentlyViewing, t) + " " + currentlyViewing.getFullYear();
    //new Intl.DateTimeFormat('no-NO', options).format(currentlyViewing);
    //console.log(formattedDate); // Output will be in Norwegian format, e.g., "torsdag, 9. november 2023"

    const onConfirm = async (time) => {
        console.log("Ran onConfirm")
        const timeCopy = { ...time }
        const isTimeEnded = timeCopy?.endDate === null ? false : true
        console.log(timeCopy)
        console.log(isTimeEnded)
        setCountUp(!isTimeEnded)
        setTime(old => {
            return {
                ...time,
                id: isTimeEnded ? createId() : time.id,
                title: "",
                startDate: isTimeEnded ? undefined : time?.startDate,
                endDate: null,
                stoppet: isTimeEnded,
                pause: isTimeEnded ? timeCopy?.pause : false,
                kunde: time?.kunde || null,
                prosjekt: time?.prosjekt || null,
            }
        })

        await uploadTimeToDb(timeCopy)
    }

    const checkForOverlap = async (time) => {
        const overlap = await checkForTimeOverlap(time, userContext.user)
        if (overlap) {
            return setAlertContext({
                text: t("You have already registered an hour", "Du har allerede en time oppføring som overlapper med denne timen, vil du overskrive den?"),
                onConfirm: () => { onConfirm(time) },
            })
        }
        onConfirm(time)
    }




    return (
        <div className="column content-ny padding-bottom-for-header" style={{ position: "relative" }}>
            <button className="btn btn-primary bottom-right-header-button-with-header"
                onClick={() => {
                    navigateToNewPage("/timeføring/ny")
                }}

            >{t("New Manual Time", "Ny Manuell Tid")}<PlusIcon /></button>

            <div className="column small-gap">
                <div className="column stretch-width time-card">
                    <div className="row flexApart">
                        <h2>{getDay(t)}</h2>
                        <h2>{getDayInMonth(new Date(), t)}, {getMonth(new Date(), t)}</h2>
                    </div>
                    <div className="row flexApart wrap-on-phone">
                        <div className="row stretch-width center">
                            <CircleIcon good={!countUp} recording={countUp} icon={AlarmIcon} />
                            <Autocomplete
                                PaperComponent={CustomPaper}
                                id="Timeføring"
                                value={time?.prosjekt || { navn: "" }}
                                options={flattenedOptions}
                                filterOptions={(options, { inputValue }) => {
                                    try {
                                        let results = [];
                                        let lowerCaseInputValue = inputValue.toLowerCase(); // Convert input value to lowercase for case-insensitive comparison

                                        options.forEach(option => {
                                            // Convert clientName and navn to lowercase and check if they include the lowercase input value
                                            if (option && option.clientName && option.clientName.toLowerCase().includes(lowerCaseInputValue)) {
                                                results.push(option);
                                            }
                                            else if (option && option.navn && option.navn.toLowerCase().includes(lowerCaseInputValue)) {
                                                results.push(option);
                                            }
                                        });

                                        return results;
                                    } catch (error) {
                                        console.error("Error filtering options:", error);
                                        return []; // Return an empty array in case of error
                                    }
                                }}
                                groupBy={(option) => option.clientName}
                                getOptionLabel={(option) => option?.nummer ? option?.nummer + " - " + option.navn : option.navn}
                                style={{ backgroundColor: "var(--input-background)", borderRadius: 5, }}
                                sx={{ ...customSxStyles, minWidth: 200, backgroundColor: "var(--input-background)", backgroundClip: "var(--input-background)" }}
                                renderInput={(params) => <TextField {...params} label={t("Project", "Prosjekt")} />}
                                renderGroup={(params) => (
                                    <div key={params.key} style={{ background: "var(--input-background)" }}>
                                        <p className="orange" style={{ marginLeft: 10 }}>{params.group}</p>
                                        <p className="stretch-width" style={{ background: "var(--input-background)", padding: "10px 5px", listStyle: 'none', margin: 0 }}>
                                            {params.children}
                                        </p>
                                    </div>
                                )}
                                fullWidth={true}
                                onChange={async (e, value) => {
                                    console.log(value)
                                    if (!value) return setTime((old) => { return { ...old, kunde: null, prosjekt: null } })
                                    //const getKundeById = await getClientById(value.clientId)
                                    //const kundeData = getKundeById.data
                                    //console.log(kundeData)
                                    setTime((old) => { return { ...old, prosjekt: value } })
                                }}
                            />
                        </div>
                    </div>

                    <div className="row flexApart">
                        <p>{t("Time", "Tid")}:</p>
                        <Timer startDate={time.startDate} countUp={countUp} />
                    </div>
                </div>

                <div className="row flexApart stretch-width">
                    <button className={"btn btn-primary" + (time?.pause ? " buttonSelected" : "")} onClick={
                        () => {
                            let timeCopy = { ...time }
                            timeCopy.pause = timeCopy.pause ? false : {
                                hours: 0,
                                minutes: 30,
                            }
                            setTime(timeCopy)
                            if (time.startDate) checkForOverlap(timeCopy)
                        }
                    }>{t("Pause", "Pause")} <PauseIcon /></button>

                    {time.startDate === undefined ?
                        <ButtonOnlyHitOnce key="start-button" className="btn btn-primary good" onClick={
                            async () => {
                                console.log(time)
                                const tempUpdate = {
                                    ...time,
                                    startDate: new Date(),
                                    stoppet: false,
                                }
                                setTime(tempUpdate)
                                setCountUp(true)
                                //checkForOverlap(tempUpdate)
                                await uploadTimeToDb(tempUpdate)
                            }
                        }>{t("Start", "Start")} <PlayIcon /></ButtonOnlyHitOnce>
                        :
                        <ButtonOnlyHitOnce key="stop-button" className="btn btn-primary recording" onClick={
                            () => {
                                let timeCopy = { ...time }
                                timeCopy.endDate = new Date()
                                console.log(timeCopy)
                                const overlap = checkForOverlap(timeCopy)
                            }
                        }>{t("Stop", "Stopp")} <SquareRounded /></ButtonOnlyHitOnce>
                    }
                </div>
            </div>

            <div className="column small-gap" style={{ paddingTop: "3em" }}>
                <div className="row flexApart wrap center-column">

                    <h1 style={{ paddingLeft: 25 }}>{calculateAllTimePassed(
                        filterTimesByDates(
                            previousRecordings, moment(currentlyViewing).startOf('day'), moment(currentlyViewing).endOf('day')
                        )).toString().replace(".", ",")
                    } t
                    </h1>

                    <h2 className="row">
                        {formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1)}
                    </h2>
                </div>

                <hr />
            </div>

            <LoadingWhileEmpty gottenInformation={fetchedTimes}>
                {currentlyViewingPrevTimeStamps.map((time) => {
                    if (time?.endDate) return (
                        <SimplifiedTimeCard key={time.id} time={time} onEdit={
                            (e) => {
                                let timeCopy = { ...time }
                                timeCopy.title = e.target.value

                                checkForOverlap(timeCopy)
                            }
                        } onClick={() => {
                            navigateToNewPage("/timeføring/" + time.id, {
                                timeToEdit: {
                                    ...time,
                                    open: true,
                                    startDate: createDateOutOfFirebaseTimestamp(time.startDate),
                                    endDate: createDateOutOfFirebaseTimestamp(time.endDate),
                                    //tilleg: time.tilleg,
                                }
                            })
                        }} />
                    )
                })}
            </LoadingWhileEmpty>


            {//<TimeCard time={information} />
            }
        </div>
    )
}

export function getDay(t) {
    const date = new Date()
    const day = date.getDay()
    switch (day) {
        case 0:
            return t("Sunday", "Søndag")
        case 1:
            return t("Monday", "Mandag")
        case 2:
            return t("Tuesday", "Tirsdag")
        case 3:
            return t("Wednesday", "Onsdag")
        case 4:
            return t("Thursday", "Torsdag")
        case 5:
            return t("Friday", "Fredag")
        case 6:
            return t("Saturday", "Lørdag")
        default:
            return t("Unknown", "Ukjent")
    }
}

export function getMonth(date, t) {
    //console.log(date)
    // Check if date is a timestamp, if so, convert it to a date
    let dateCopy = new Date(date)


    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }

    if (date === undefined) dateCopy = new Date()
    //console.log(dateCopy, date)

    let month = dateCopy.getMonth()
    if (typeof date === "number") month = date
    //console.log(month)

    switch (month) {
        case 0:
            return t("January", "Januar")
        case 1:
            return t("February", "Februar")
        case 2:
            return t("March", "Mars")
        case 3:
            return t("April", "April")
        case 4:
            return t("May", "Mai")
        case 5:
            return t("June", "Juni")
        case 6:
            return t("July", "Juli")
        case 7:
            return t("August", "August")
        case 8:
            return t("September", "September")
        case 9:
            return t("October", "Oktober")
        case 10:
            return t("November", "November")
        case 11:
            return t("December", "Desember")
        default:
            return t("Unknown", "Ukjent")
    }
}

export function getDayInMonth(date) {
    //console.log(date)
    let dateCopy = createDateOutOfFirebaseTimestamp(date)
    if (typeof date?.seconds === "number") {
        dateCopy = new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
    }
    if (!date) dateCopy = new Date()

    return dateCopy.getDate()
}

export function createDateOutOfFirebaseTimestamp(timestamp) {
    //console.log(timestamp)
    if (!timestamp) return null;
    let date;
    if (timestamp?.seconds !== undefined) {
        date = new Date(timestamp?.seconds *
            1000 + timestamp?.nanoseconds / 1000000);
    } else {
        date = new Date(timestamp);
    }
    return moment(date).tz('Europe/Oslo').toDate();
}

export function makeDateReadable(date, t) {
    const now = new Date();
    const firstDayOfCurrentMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    // Convert `date` to a Date object if it is a Unix timestamp.
    if (!(date instanceof Date)) {
        date = new Date(date);
    }

    // If it is under a week old, return day by name.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 7) {
        return getDayName(date, t);
    }

    // If it is within the current month, return day and month.
    if (date >= firstDayOfCurrentMonth) {
        return date.getDate() + ". " + getMonth((date.getMonth() + 1), t);
    }

    // If it is under a year old, return month and day.
    if (date.getTime() > now.getTime() - 1000 * 60 * 60 * 24 * 365) {
        return date.getDate() + ". " + getMonth((date.getMonth() + 1), t);
    }

    // If it is over a year old, return year, month, and day.
    return date.getDate() + ". " + getMonth((date.getMonth() + 1), t) + " " + date.getFullYear();
}


export function getDayName(date, t) {
    //console.log(date)
    const day = date.getDay()
    switch (day) {
        case 0:
            return t("Sunday", "Søndag")
        case 1:
            return t("Monday", "Mandag")
        case 2:
            return t("Tuesday", "Tirsdag")
        case 3:
            return t("Wednesday", "Onsdag")
        case 4:
            return t("Thursday", "Torsdag")
        case 5:
            return t("Friday", "Fredag")
        case 6:
            return t("Saturday", "Lørdag")
        default:
            return t("Unknown", "Ukjent")
    }
}

export function createNorwegianDate(date) {
    const month = date.getMonth() > 9 ? date.getMonth() : "0" + date.getMonth()
    return date.getDate() + "." + month + "." + date.getFullYear()
}

function isValidDate(dateString) {
    // Check if the format is correct (YYYY-MM-DD)
    const regex = /^\d{4}-\d{2}-\d{2}$/;
    if (!regex.test(dateString)) {
        return false;
    }

    // Parse the date parts to integers
    const parts = dateString.split("-");
    const year = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10) - 1; // Month is 0-based
    const day = parseInt(parts[2], 10);

    // Check the ranges of month and year
    if (year < 1000 || year > 3000 || month < 0 || month > 11) {
        return false;
    }

    // Create a Date object and check if it matches the input
    const date = new Date(year, month, day);
    if (date.getFullYear() !== year || date.getMonth() !== month || date.getDate() !== day) {
        return false;
    }

    return true;
}

// Convert to date if valid
export function IsActuallyDate(dateString) {
    if (isValidDate(dateString)) {
        return new Date(dateString);
    } else {
        return null;
    }
}


export const TimeChoiseModal = ({
    open, start, end, tilleg, tillegOptions,
    onDone, onClose,
    title,
    kunde, kunder,
    projekt, projekter, t
}) => {
    const [startDate, setStartDate] = useState(start)
    const [endDate, setEndDate] = useState(end)
    const [tillegToUpdate, setTillegg] = useState(tilleg)

    //console.log(tillegOptions)
    //console.log(tilleg)
    //console.log(tillegToUpdate)


    useEffect(() => {
        setStartDate(start)
        setEndDate(end)

    }, [start, end])

    //console.log(startDate, endDate)
    //console.log(start, end)

    return (
        <IonModal isOpen={open} cssClass='my-custom-class' onDidDismiss={onClose}>
            <IonContent>
                <div className="content-ny">
                    <div className="row flexApart">
                        <button className="btn btn-primary" onClick={() => {
                            onClose()
                        }}>{t("Back", "Tilbake")}</button>

                        <button className="btn btn-primary" onClick={() => {
                            onDone(startDate, endDate, tillegToUpdate)
                        }}>{t("Done", "Ferdig")}</button>
                    </div>
                    {tillegOptions &&
                        <div className="column flexApart">
                            <h2>{t("Additions", "Tillegg")}</h2>

                            <div className="typeAvTillegg">
                                <CustomDropdown options={tillegOptions} selected={tillegToUpdate} onChange={(e) => setTillegg(e)} />
                            </div>
                        </div>
                    }
                    <h2>{t("From", "Fra")}</h2>
                    <TimeChooser date={startDate} onChange={(e) => setStartDate(e)} />
                    <h2>{t("To", "Til")}</h2>
                    <TimeChooser date={endDate} onChange={(e) => setEndDate(e)} />

                </div>
            </IonContent>
        </IonModal>
    )
}



export const customSxStyles = {
    // Input text color
    '& .MuiAutocomplete-inputRoot': {
        color: "white",
    },
    // Focused input border color
    '& .MuiAutocomplete-inputFocused': {
        borderColor: "var(--contrastColor)",
    },
    // Normal input border color
    '& .MuiOutlinedInput-notchedOutline': {
        borderColor: "white",
    },
    // Hover input border color
    '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: "var(--contrastColor)",
    },
    // Label color
    '& .MuiInputLabel-root': {
        color: "white",
    },
    // Focused label color
    '& .Mui-focused .MuiInputLabel-root': {
        color: 'var(--contrastColor) !important',
    },
    // Shrunk label color
    '& .MuiInputLabel-shrink': {
        color: 'var(--contrastColor) !important',
    },
    // Autocomplete paper styles
    '& .MuiAutocomplete-paper': {
        borderTop: '2px solid var(--contrastColor)',
        borderBottom: '2px solid var(--contrastColor)',
        backgroundColor: "red",
    },
    // No options background color
    "& .MuiAutocomplete-noOptions": {
        backgroundColor: "red",
    },

    "& .MuiAutocomplete-listbox": {
        backgroundColor: "var(--input-background)",
    },
};

export const CustomPaper = styled('div')({
    backgroundColor: "var(--input-background)",
    borderRadius: 5,
});

export function filterTimesByDates(times, startDate, endDate) {
    return times.filter(time => {
        const timeDate = createDateOutOfFirebaseTimestamp(time.startDate)
        return timeDate >= startDate && timeDate <= endDate
    })
}