//import BarChartExport from "../../../components/miniComponents/barChart";

import { useContext, useEffect, useRef, useState } from "react";
import { TimeChoiseModal, createDateOutOfFirebaseTimestamp, getMonth } from "../timeføring";
import { createId, getAuthUser, getAuthUserName, getRecordedTimeFromDB, getRecordedTimeFromDBByDate, getRecordedTimeFromDBbyUserId, getRoles } from "../../../firebase";
import CalendarChart from "../../../components/miniComponents/calendarOverview";
import ExportTimeToPdf, { roundToNearestHalfHour } from "../../../components/exportTimeToPdf";
import { TimeContextCreation } from "../timetrackerMenu";
import exportTimeToPdf from "../../../components/exportTimeToPdf";
import { IonSearchbar, IonSelect, IonSelectOption } from "@ionic/react";
import { CheckIfOnPC, UserContext } from "../../../App";
import ButtonListButton from "../../../components/miniComponents/buttonListButton";
import { BarChart } from "recharts";

import TimeBarChart from "../../../components/timeBarChart";
import DateRangeSelector from "../../../components/dateSelector";
import PeopleAndRolesSearchWithPopup from "../../../components/searchWithPopups/peopleAndRolesSearch";
import DefaultWrapper from "../../../components/defaultWrapper";
import { ArrowRight, CalendarMonth, PictureAsPdf } from "@mui/icons-material";
//import BarChartExport from "../../../components/miniComponents/barChart";
import { useTranslation } from "react-i18next";
import { calculateTimePassed } from "../../../components/timetracking/timeCards";
import { getCompanyUser, getCompanyUsers } from "../../../database/users";



export default function AdminTimeOverview() {
    const { t } = useTranslation();
    const [invitedUsers, setInvitedUsers] = useState([]);
    const [search, setSearch] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const [usersInCompany, setUsersInCompany] = useState([]); // [user1, user2, user3
    const [filteredUsersInCompany, setFilteredUsersInCompany] = useState([]); // [user1, user2, user3

    const [rolesThatCanBeInvited, setRolesThatCanBeInvited] = useState([]); // [role1, role2, role3
    const [autoInviteRoles, setAutoInviteRoles] = useState([]);
    const selectRef = useRef(null);



    const [previousRecordings, setPreviousRecordings] = useState([])
    const [arrayOfPreviousRecordings, setArrayOfPreviousRecordings] = useState([])
    const [timeChoiseModalInfo, setTimeChoiseModalInfo] = useState({
        open: false,
        startDate: localStorage.getItem("startDate") ? new Date(localStorage.getItem("startDate")) : new Date(),
        endDate: localStorage.getItem("endDate") ? new Date(localStorage.getItem("endDate")) : new Date(),
        title: "",
    })
    //const { timeContext, setTimeContext } = useContext(TimeContextCreation);
    const { userContext, setUserContext } = useContext(UserContext);
    const user = userContext.user;

    const [usersWithDager, setUsersWithDager] = useState([])
    const [timebar, setTimebar] = useState(<TimeBarChart arrayOfDays={usersWithDager} startDate={timeChoiseModalInfo.startDate} endDate={timeChoiseModalInfo.endDate} />)

    useEffect(() => {
        setTimebar(<TimeBarChart arrayOfDays={usersWithDager} startDate={timeChoiseModalInfo.startDate} endDate={timeChoiseModalInfo.endDate} />)
    }, [usersWithDager])

    // Edit FilteredUsersInCompany to not include users that are already invited
    useEffect(() => {
        const filteredUsers = usersInCompany.filter((user) => {
            return !invitedUsers.includes(user);
        });
        setFilteredUsersInCompany(filteredUsers);
    }, [invitedUsers, usersInCompany]);

    useEffect(() => {
        async function getRolesFromCompany() {
            //const company = await getRolesFromCompany();
            const onUpdate = (roles) => {
                console.log(roles);
                setRolesThatCanBeInvited(roles);
            }
            getRoles(onUpdate);
        }
        getRolesFromCompany();
    }, []);

    useEffect(() => {
        async function getUsers() {
            const onUpdate = (users) => {
                setUsersInCompany(users);
            }
            getCompanyUsers(null, onUpdate);
        }
        getUsers();
    }, []);

    useEffect(() => {
        async function filterUsers() {
            const results = usersInCompany.filter((user) => {
                if (!user.navn) return false;
                if (!search) return true;
                return user.navn.toLowerCase().includes(search.toLowerCase());
            });
            setSearchResults(results);
        }
        filterUsers();
    }, [search, usersInCompany]);

    useEffect(() => {
        console.log(timeChoiseModalInfo);
        if (timeChoiseModalInfo.startDate !== localStorage.getItem("startDate")) {
            localStorage.setItem("startDate", timeChoiseModalInfo.startDate)
        }
        if (timeChoiseModalInfo.endDate !== localStorage.getItem("endDate")) {
            localStorage.setItem("endDate", timeChoiseModalInfo.endDate)
        }


    }, [timeChoiseModalInfo])

    useEffect(() => {
        const onUpdate = (data) => {
            //console.log(data);  // Add this
            setPreviousRecordings(data)
        }

        getRecordedTimeFromDBByDate(onUpdate, timeChoiseModalInfo.startDate, timeChoiseModalInfo.endDate)
    }, [timeChoiseModalInfo])

    useEffect(() => {
        async function makeDager() {

            const users = fuseUsersToGetTimeForIntoOneArray(usersInCompany, invitedUsers, autoInviteRoles);

            const usersWithDager = users.map((user) => {
                const dager = previousRecordings.filter((recording) => {
                    return recording.userId === user.id;
                });
                return {
                    ...user,
                    dager,
                };

            });

            const usersWithDagerSorted = usersWithDager.map((user) => {
                const dager = user.dager.sort((a, b) => {
                    if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
                        return b.endDate.toDate() - a.endDate.toDate();
                    } else {
                        return 0;
                    }
                });
                return {
                    ...user,
                    dager,
                };
            });

            const usersWithDagerSortedAndGrouped = usersWithDagerSorted.map(async (user) => {
                const dagerForUser = await getRecordedTimeFromDBbyUserId(user.id, timeChoiseModalInfo.startDate, timeChoiseModalInfo.endDate);
                const groupedByDay = dagerForUser.reduce((acc, current) => {
                    if (!current.endDate) return acc;
                    if (current.startDate) {
                        const date = createDateOutOfFirebaseTimestamp(current.startDate) // convert Firestore timestamp to JS Date

                        if (!isNaN(date)) {
                            const day = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;

                            if (!acc.has(day)) {
                                acc.set(day, []);
                            }

                            acc.get(day).push(current);
                        }
                    }

                    return acc;
                }, new Map());

                for (let [_, recordings] of groupedByDay) {
                    recordings.sort((a, b) => {
                        if (a.endDate && typeof a.endDate.toDate === 'function' && b.endDate && typeof b.endDate.toDate === 'function') {
                            return b.endDate.toDate() - a.endDate.toDate();
                        } else {
                            return 0;
                        }
                    });
                }

                const arrayOfPreviousRecordings = Array.from(groupedByDay.entries()).sort().reverse().map(([date, recordings]) => {
                    const [year, month, day] = date.split('-');
                    const title = `${day}. ${getMonth(parseInt(month) - 1, t)} ${year}`; // change the title to the desired format

                    return {
                        title,
                        dates: recordings,
                    };
                });

                return {
                    ...user,
                    dager: arrayOfPreviousRecordings,
                };
            });

            console.log(await usersWithDagerSortedAndGrouped);

            // make sure all promises are fulfilled
            const usersWithDagerSortedAndGroupedDone = await Promise.all(usersWithDagerSortedAndGrouped);
            console.log(usersWithDagerSortedAndGroupedDone);

            setUsersWithDager(usersWithDagerSortedAndGroupedDone);
        }

        makeDager();
    }, [previousRecordings, invitedUsers, autoInviteRoles, usersInCompany]);


    const fuseUsersToGetTimeForIntoOneArray = (allUsers, usersAlreadyFiltered, roles) => {
        let users = [...usersAlreadyFiltered];
        roles.forEach((role) => {
            const usersInRole = allUsers.filter((user) => {
                if (users?.find((u) => u.id === user.id)) return
                return user.roles.includes(role.id);
            });
            users = [...users, ...usersInRole];
        });
        return users;
    }



    return (
        <DefaultWrapper noBackButton={CheckIfOnPC()}>
            <div className="content-ny">
                <div className="row">
                    <div className="column stretch-width">
                        <PeopleAndRolesSearchWithPopup onChange={({ people, roles }) => {
                            setInvitedUsers([...people, ...roles]);
                        }} idOnly={false} addedRoles={autoInviteRoles} addedPeople={invitedUsers} />
                        


                        <div className="row small-gap">
                            <button style={{ padding: 10 }} className={"row small-gap"}
                                onClick={() => {

                                    setTimeChoiseModalInfo({ ...timeChoiseModalInfo, open: true })
                                }}>

                                <p style={{ fontSize: 10 }}>{timeChoiseModalInfo.startDate.toLocaleDateString()}</p>
                                <ArrowRight width={10} height={10} />
                                <p style={{ fontSize: 10 }}>{timeChoiseModalInfo.endDate.toLocaleDateString()}</p>
                                <CalendarMonth width={10} height={10} />
                            </button>
                            <DateRangeSelector startDate={timeChoiseModalInfo.startDate} endDate={timeChoiseModalInfo.endDate}
                                onChange={({ startDate, endDate }) => {
                                    setTimeChoiseModalInfo({
                                        ...timeChoiseModalInfo,
                                        startDate,
                                        endDate,
                                    })
                                }} />
                        </div>
                        <div className="row">
                            {timebar}
                            <div className="column">
                                <div className="orange-card column center" style={{
                                    minWidth: 140,
                                }}>
                                    <h3>{t("Time Total", "Timer totalt")}</h3>
                                    <h1 className="bold">{ // Total time worked for all users
                                        usersWithDager.length >= 0 ? calculateUserHours(usersWithDager, t) : "0 " + t("h", "t")
                                    }</h1>
                                </div>
                            </div>
                        </div>


                        <TimeChoiseModal t={t} open={timeChoiseModalInfo.open} start={timeChoiseModalInfo.startDate} end={timeChoiseModalInfo.endDate} title={timeChoiseModalInfo.title} onDone={
                            (updatedStart, updatedEnd) => {
                                setTimeChoiseModalInfo({
                                    ...timeChoiseModalInfo,
                                    startDate: updatedStart,
                                    endDate: updatedEnd,
                                    open: false
                                })
                            }
                        }
                            onClose={() => setTimeChoiseModalInfo({ ...timeChoiseModalInfo, open: false })}
                        />
                    </div>

                    <div className="column">
                        <button className="column" onClick={async () => {
                            const user = await getAuthUser();
                            const userName = await getCompanyUser(user)
                            exportTimeToPdf({ people: usersWithDager, fromTime: timeChoiseModalInfo.startDate, endTime: timeChoiseModalInfo.endDate })
                        }
                        } > <PictureAsPdf style={{ width: 40, height: 40 }} />
                            <h3>{t("Export to PDF", "Eksporter til PDF")}</h3></button>
                    </div>
                </div>
            </div>
        </DefaultWrapper>
    )
}


const calculateUserHours = (usersWithDager, t) => {
    let totalHours = 0;
    usersWithDager.forEach((user) => {
        user.dager.forEach((day) => {
            day.dates.forEach((date) => {
                const hours = Number(calculateTimePassed(date).value.replace(",", "."));
                totalHours += hours
            });
        });
    });
    // convert to 1,5 hours format, where 1:30 is 1.5
    const hoursToReturn = totalHours
    return (Math.round(hoursToReturn * 10) / 10).toString().replace(".", ",") + " " + t("h", "t");
}

