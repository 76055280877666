import React, { useState, useRef, useEffect } from 'react';
import { createReport, getClients, getNotSentReports, getReportNumber, getSentReports, setReportNumber } from '../../firebase';
import RapportCard from '../../components/rapport-card';
import ClientCard from '../../components/client';
import { useHistory } from 'react-router';
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/react';
import DefaultWrapper from '../../components/defaultWrapper';
import ButtonListButton from '../../components/miniComponents/buttonListButton';

import { ReactComponent as UserIcon } from '../../assets/icons/material-symbols_person.svg';
import LoadingWhileEmpty from '../../components/miniComponents/loadingWhileEmpty';
import { CheckIfOnPC } from '../../App';
import { Avatar } from '@mui/material';
import { useTranslation } from 'react-i18next';


export function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
}

export function stringAvatar(name) {
    if (name === "") return {
        sx: {
            bgcolor: "var(--contrastColor)",
            //icon color
            color: "var(--inputBackground)",
        },
    }


    return {
        sx: {
            bgcolor: stringToColor(name),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ').length > 1 ? name.split(' ')[1][0] : ""}`,
    };
}

export default function ClientList(props) {
    const { t } = useTranslation();
    const [clients, setClients] = useState([]);
    const history = useHistory();

    function navigateToNewPage(path, information) {
        history.push({
            pathname: path,
            state: information
        });
    }

    async function getClientList() {
        const onUpdate = (querySnapshot) => {
            const clientList = [];
            querySnapshot.forEach((doc) => {
                const clientData = doc.data();
                clientList.push(clientData);
            });
            console.log(clientList)
            setClients(clientList)
        };


        const clientList = await getClients(onUpdate)
        setClients(clientList)
    }

    useEffect(() => {
        getClientList()
    }, [])

    console.log(clients)

    return (
        <DefaultWrapper lagring={true} noBackButton={CheckIfOnPC()}>
            <div className='clientList content-ny column'>
                <div className='flexApart'>
                    <h1>{t("Customers", "Kunder")}</h1>
                    <div className='flexClose'>
                        <button className='ny' onClick={() => navigateToNewPage('/manageClient', null)}>
                            {t("New", "Ny")} +
                        </button>
                    </div>
                </div>
                <div className='gap column'>
                    <LoadingWhileEmpty buttonList={true}>
                        {clients.map((client, index) => {
                            return <ButtonListButton
                                key={client.id || index}
                                client={client}
                                title={client.navn}
                                description={client.addresse}
                                icon={UserIcon}
                                avatar={() => {
                                    return <Avatar {...stringAvatar(client?.navn || "")}></Avatar>
                                }}
                                //onPress={(e) => navigateToNewPage('/client', client)}
                                onClick={(e) => navigateToNewPage('/client', client)}
                            />
                        })}
                    </LoadingWhileEmpty>
                </div>
            </div>
        </DefaultWrapper >
    );
}
