import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { addListeners, addNotificationReceivedListener, checkIfAuthenticated, checkPermissions, getAuthUser, getCompanyDetails, getCompanyId, getMessages, getUser, registerNotifications, requestPermissions, saveFCMTokenToUser } from './firebase'

import { Redirect, Route, useHistory, useLocation } from 'react-router-dom';
import {
  IonAlert,
  IonApp,
  IonIcon,
  IonLabel,
  IonPage,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, square, triangle, home, calculator, person, chatboxEllipses, reader } from 'ionicons/icons';
import Tab1 from './pages/Tab1';

import Tab3 from './pages/login/Tab3';
import { IonBadge } from "@ionic/react";



/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './css/ion.css';

// My own styles
import './css/default.css';
import './css/rapporter.css';
import './css/clients.css';
import './css/utstyr.css';
import './css/kalkulator.css';
import './css/chat.css';
import './css/timetracker.css';
import './css/pdf.css';

// Pages

import { createContext, useContext, useEffect, useRef, useState } from 'react';

import useAuth from './useauth.js';

import { Capacitor, Plugins } from '@capacitor/core';
import { Deeplinks } from '@awesome-cordova-plugins/deeplinks'

//import { FCM } from "@capacitor-community/fcm";
import { PushNotifications } from "@capacitor/push-notifications";


import { App as CapApp } from '@capacitor/app';
import { Network } from '@capacitor/network';
import CustomInput from './components/miniComponents/customInput.jsx';
import PathRouter from './Router';
import { CompanyProvider } from './contexts/companyContext';
import { getCompanyUser, getCompanyUserRoles } from './database/users';
import UploadingContextProvider from './contexts/loadingContext';




const { MyDeepLinkPlugin } = Plugins;


export const ReportContextCreation = createContext();

export const MyContext = createContext();

export const HeaderContext = createContext();

export const UserContext = createContext();

export const TimeContextCreation = createContext();

export const ResetContext = createContext();

export const AlertContext = createContext();

export const ResetContextProvider = ({ children }) => {
  const [reset, setReset] = useState(false);

  useEffect(() => {
    if (reset) {
      setTimeout(() => {
        setReset(false);
      }, 100);
    }
  }, [reset]);

  return (
    <ResetContext.Provider value={{ reset, setReset }}>
      {!reset && children}
    </ResetContext.Provider>
  );
}

export const TimeContextProvider = ({ children }) => {
  const [timeContext, setTimeContext] = useState({

  });

  return (
    <TimeContextCreation.Provider value={{ timeContext, setTimeContext }}>
      {children}
    </TimeContextCreation.Provider>
  );
}



// Create a Provider component
export const ReportContextProvider = ({ children }) => {
  const [reportContext, setReportContext] = useState(false);

  useEffect(() => {
    //if (reportContext.sendingFirmDetails) return;
    if (reportContext?.sendingFirmDetails) return
    if (!getCompanyId()) return;
    const setSendingFirm = async () => {
      const sendingFirmDetails = await getCompanyDetails();
      console.log("sendingFirmDetails", sendingFirmDetails);
      setReportContext(old => {
        return {
          ...old,
          sendingFirmDetails: sendingFirmDetails
        }
      })
    }
    setSendingFirm();
  }, [reportContext]);

  return (
    <ReportContextCreation.Provider value={{ reportContext, setReportContext }}>
      {children}
    </ReportContextCreation.Provider>
  );
}

export const HeaderContextProvider = ({ children }) => {
  const [headerContext, setHeaderContext] = useState(true);
  const [showMenu, setShowMenu] = useState(true);

  return (
    <HeaderContext.Provider value={{ headerContext, setHeaderContext, showMenu, setShowMenu }}>
      {children}
    </HeaderContext.Provider>
  );
}

export const AlertContextProvider = ({ children }) => {
  const [alertContext, setAlertContext] = useState(false);
  const [typeCode, setTypeCode] = useState("test")
  const [codeInput, setCodeInput] = useState("")
  const background = useRef(null)
  const [networkStatus, setNetworkStatus] = useState(true)
  //const [onConfirm, setOnConfirm] = useState(() => { });

  useEffect(() => {
    console.log("onConfirm changed")
    console.log(alertContext.onConfirm)
  }, [alertContext])



  useEffect(() => {
    console.log("alertContext changed")
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      logCurrentNetworkStatus();
    });

    const logCurrentNetworkStatus = async () => {
      const status = await Network.getStatus();
      console.log('Network status:', status);
      if (status.connected === networkStatus) return;
      setNetworkStatus(status.connected);
      console.log('Network status:', status);
      console.log('Is connected?', status.connected);
      console.log(alertContext)

      if (status.connected) return console.info("Du har nett igjen. 🛜");
      console.error("Har ikke nettverks tilgang ⛔🛜")
      setAlertContext({ text: "Du er offline, vennligst sjekk nettverket ditt", onConfirm: () => { setAlertContext(false) }, onConfirmText: "OK" });
    };
    logCurrentNetworkStatus();
  }, [])

  useEffect(() => {
    setCodeInput("")
    setTypeCode(Math.random().toString(36).substr(2, 5))
  }, [alertContext.confirmByTyping])

  return (
    <AlertContext.Provider value={{ alertContext, setAlertContext }}>
      {
        alertContext &&
        <div ref={background} className='alert-background' onClick={(e) => {
          if (e.target !== background.current) return
          setAlertContext(false)
        }}>
          <div className='alert-card'>
            <div className='alert-header'>{alertContext?.title || "Alert"}</div>
            <div className='alert-content column'>
              <div className='alert-text'> {alertContext.text}</div>
              {alertContext.confirmByTyping ?
                <div className='row'>
                  <p>Skriv in </p><p className='bold'>{typeCode}</p><p> for å bekrefte</p>
                </div>
                : null
              }
              {alertContext.confirmByTyping ?
                <CustomInput onChange={(e) => setCodeInput(e.target.value)} value={codeInput} />
                : null
              }
              <div className='alert-buttons'>
                <button onClick={() => setAlertContext(false)}>Avbryt</button>
                <button disabled={
                  alertContext?.confirmByTyping ? typeCode !== codeInput : false
                } className='orangeButton' onClick={() => {
                  if (alertContext.onConfirm) alertContext.onConfirm()
                  setAlertContext(false)
                }}>{alertContext?.onConfirmText ? alertContext.onConfirmText : "Overskriv"}</button>

              </div>
            </div>
          </div>
        </div>
      }
      {children}
    </AlertContext.Provider>
  );
}


export const UserContextProvider = ({ children }) => {
  const companyId = localStorage?.getItem("companyId");
  //console.log("Initializing user context provider")

  // Initialize state based on local storage or default value


  const initialState = () => {
    console.log("initializing user context with", companyId)
    try {
      const dataFromStorage = JSON.parse(localStorage?.getItem(`${companyId}User`));
      if (dataFromStorage) {
        return dataFromStorage;
      }
    }
    catch (e) {
      console.error("Error parsing user data from localStorage:", e);
    }
    return { user: null, companyUser: null };
  }
  const [state, setState] = useState(initialState());

  FirebaseAuthentication.addListener('authStateChange', (user) => {
    console.log("Auth state changed")
    console.log(user)
    setState(prevState => { return { ...prevState, user: user?.user ? user?.user : null } });
    if (user?.user) localStorage?.setItem("loggedIn", "true");
    localStorage?.setItem("userId", user?.user?.uid)
  })

  useEffect(() => {
    console.log("userContext:" + JSON.stringify(state) + " END OF USERCONTEXT");

    // Save to localstorage when state changes
    localStorage?.setItem(`${companyId}User`, JSON.stringify(state));
  }, [state]);

  const setUserContext = (newContext) => {
    setState(prevState => {
      return {
        ...prevState,
        ...newContext
      }
    });
  }

  return (
    <UserContext.Provider value={{ userContext: state, setUserContext: setState }}>
      {children}
    </UserContext.Provider>
  );
}



async function setupFCM() { //notifications
  return
  /*try {
    // Request permission to use push notifications
    await PushNotifications.requestPermissions();
    await PushNotifications.register();
    console.warn("registered push notifications")
    // Get FCM Token and save it to Firebase
 
    if (!checkIfAuthenticated()) return localStorage.removeItem("fcm");
    if (localStorage?.getItem("fcm") !== "true") {
      console.warn("registered push notifications")
      const token = await FCM.getToken();
      console.log('FCM Token:', token);
 
      // Save this token securely to your server or Firebase
      saveFCMTokenToUser(token);
      localStorage?.setItem("fcm", "true");
    }
 
 
  } catch (error) {
    console.error('Error initializing FCM', error);
  }*/
}



// Call setupFCM function appropriately in your component


const App = () => {
  console.log("rendering app")
  const { loading } = useAuth();
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Fix: use setIsAuthenticated instead of setisAuthenticated
  const [messages, setMessages] = useState([]);
  const [isInChatComponent, setIsInChatComponent] = useState(false);

  useEffect(() => {


    if (Capacitor.isNativePlatform()) {
      checkPermissions();
      requestPermissions();
      addNotificationReceivedListener();
    }

    //if (Capacitor.isNativePlatform()) registerNotifications();


    Deeplinks.route({
      '/invitasjoner': match => {
        console.log('Successfully matched route', match);
        const fullUrl = match.$link.url;
        handleDeepLink(fullUrl);
      }
    }).subscribe({
      next: match => {
        console.log(match);
        console.log('Successfully matched route', match);
      },
      error: nomatch => {
        console.error('Got a deeplink that didn\'t match', nomatch);
      }
    });

    /*CapApp.addListener('appUrlOpen', (data) => {
      console.log('App opened with URL:', data);
    }
    );
 
    Deeplinks.addListener('deepLinkOpen', (data) => {
      console.log('DeepLinkOpen with URL:', data);
    }
    );
 
    Deeplinks.addListener('appUrlOpen', (data) => {
      console.log('App opened with URL:', data);
    }
    );*/
    document.addEventListener('appUrlOpen', (data) => {
      console.log('App opened with URL:', data);
    }
    );

    document.addEventListener('deepLinkOpen', (data) => {
      console.log('DeepLinkOpen with URL:', data);
    }
    );

    document.addEventListener('appUrlOpen', (data) => {
      console.log('App opened with URL:', data);
    }
    );

    CapApp.addListener('appUrlOpen', (data) => {
      console.log('App URL open:', data.url);
      handleDeepLink(data.url);
    });
  }, []);

  const handleDeepLink = async (url) => {
    const parsedUrl = new URL(url);

    // Get the query parameters using the URL object
    const invitationId = parsedUrl.searchParams.get('invitationId');
    console.log("invitationId: " + invitationId);
    console.log("handleDeepLink", url);
    /*const query = new URLSearchParams(window.location.search);
    const invitationId = query.get('invitationId');*/
    window.location.href = `/invitasjoner?invitationId=${invitationId}`;
  };


  /*useEffect(() => {
    async function getUCntxt() {
      const userContextToUpdate = await getUserContext();
      setUserContext(userContextToUpdate);
    }
    getUCntxt();
  }, []);*/

  // Modify the useEffect hook to update the newMessagesCount state

  // Needs update: As we're moving into groups, we need to update the last seen timestamp for each group. 

  useEffect(() => {
    if (Capacitor.isNativePlatform()) setupFCM();
  }, []);


  return (
    <IonApp >
      <UserContextProvider>
        <CompanyProvider>
          <ReportContextProvider>
            <ResetContextProvider>
              <AlertContextProvider>
                <HeaderContextProvider>
                  <TimeContextProvider>
                    <UploadingContextProvider>
                      <PathRouter />
                    </UploadingContextProvider>
                  </TimeContextProvider>
                </HeaderContextProvider>
              </AlertContextProvider>
            </ResetContextProvider>
          </ReportContextProvider>
        </CompanyProvider>
      </UserContextProvider>
    </IonApp>
  );
}

export default App;



function KeyedRoute({ component: Component, ...rest }) {
  const location = useLocation();

  return (
    <Route {...rest}>
      <Component key={location.pathname} />
    </Route>
  );
}


export async function getUserContext() {
  const user = await getAuthUser();

  //If no user, try again in 5 seconds
  console.log("user", user);
  const companyIdToGet = getCompanyId();
  if (!companyIdToGet) return setTimeout(getUserContext, 5000);
  const companyUser = await getCompanyUser(user);
  if (!companyUser) return setTimeout(getUserContext, 5000);
  console.log("userContext", user, companyUser);
  // If rejected, try again
  // Timer to try again after 5 seconds
  if (!user || !companyUser) setTimeout(getUserContext, 5000)
  //if (!user || !companyUser) return;

  const roles = await getCompanyUserRoles(companyUser, user);

  return { user: user, companyUser: { ...companyUser, permission: roles.permissions } };
}


export function CheckIfOnPC() {
  return window.innerWidth > 800;
}