import { collection, getDocs, onSnapshot, query } from 'firebase/firestore';
import { db, app, getCompany, getCompanyId, companyId } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';








// Create a new company
export async function checkCouponCode(string) {
    const functions = getFunctions(app, 'europe-west2');
    const checkSalesCode = httpsCallable(functions, 'checkCouponCode');
    const data = await checkSalesCode({ couponCode: string });
    if (data?.error) {
        throw new Error(data.error);
    }
    return data;
}

export async function getRoles(params, onUpdate) {
    // Get an array of the roles in the company
    const q = query(collection(db, "Kompanier", companyId(), "roles"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const roles = [];
        querySnapshot.forEach((doc) => {
            roles.push(doc.data());
        });
        onUpdate(roles);
    });

    // return roles
    const documents = await getDocs(q);
    const roles = [];
    documents.forEach((doc) => {
        roles.push(doc.data());
    });
    return {value: roles, subscription: unsubscribe};
}