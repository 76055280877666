import { getFirmSettings } from "../firebase";





export async function getCompanyTimeSettings() {
    const settings = await getFirmSettings("timeforingsInstillinger");
    console.log(settings)
    return {value:settings};
}